import React from "react";
import TestimonialsLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";
import { useTranslation } from "react-i18next";

const TestimonialOne = () => {
  const { t } = useTranslation("translation");
  const startUpNightParagraph = t("startUpNightsParagraph", { ns: "translation" });
  const websiteLink = "www.startup-nights.ch";
  const name = "Alyssia Kugler";
  const title = "Director";
  return (
    <TestimonialsLayout
      brandLogo={images.startUpN}
      description={startUpNightParagraph}
      websiteLink={websiteLink}
      avatar={images.alissia}
      authorName={name}
      authorTitle={title}
    />
  );
};

export default TestimonialOne;
