import React, { useRef } from "react";
import TermsOfUseHeader from "./terms-of-use-header/TermsOfUseHeader";
import "../../../../resources/styles/pages/terms-of-use.css";
import GetInTouchTermsOfUse from "./getintouch-terms-of-use/GetInTouchTermsOfUse";
import MetaData from "../common/metadata/MetaData";

const TermsOfUse = () => {
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const fourthTermsFourTitle = "Beantwortungs-\nÜberschreitungs-\ngebühren";
  const ninthTermsOneTitle = "Passwort-, Email-\nund Kontodaten-\nsicherheit";

  const listArr = [
    {
      id: 1,
      title: "1. Vertrag und Vertragswiderruf",
    },
    {
      id: 2,
      title: "2. Gebühren und Zahlungen",
    },
    {
      id: 3,
      title: "3. Datenschutz",
    },
    {
      id: 4,
      title: "4. Ihre Inhalte",
    },
    {
      id: 5,
      title: "5. Geistiges Eigentum von Squib",
    },
    {
      id: 6,
      title: "6. Ressourcen Dritter",
    },
    {
      id: 7,
      title: "7. Kontoverwaltung",
    },
    {
      id: 8,
      title: "8. Aussetzung und Beendigung von Services",
    },
    {
      id: 9,
      title: "9. Änderungen und Aktualisierungen",
    },
    {
      id: 10,
      title: "10. Haftungsausschlüsse und Entschädigung",
    },
    {
      id: 11,
      title: "11. Sonstige Bedingungen",
    },
  ];

  const scrollToSection = (index: number, e: React.MouseEvent) => {
    e.preventDefault();
    const section = sectionsRef.current[index];
    if (section) {
      const yOffset = -200;
      const yPosition = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

  return (
    <>
      <MetaData title="Nutzungsbedingungen" />
      <div className="terms-of-use-container">
        <div className="terms-of-use-header-wrapper">
          <TermsOfUseHeader />
        </div>
        <div className="terms-of-use-cont">
          <div className="first-terms-cont">
            <h4>Letzte Änderung: 15. März 2024</h4>
            <p>
              Diese Nutzungsbedingungen gelten für Ihren Zugriff auf die und Ihre Nutzung der Dienstleistungen, Websites und Apps von Squib,
              die Sie als «Service(s)» auf den Websites von Squib kaufen oder abonnieren.
            </p>
            <p>
              Überall dort, wo Sie in diesen Nutzungsbedingungen «Sie», «Ihnen», «Benutzer» oder «Kunde» sehen, bezieht sich diese
              Bezeichnung auf Sie, die Nutzerin oder den Nutzer der Squib Services. Ausserdem beziehen sich die Begriffe «Wir», «Uns» und
              «Squib» auf die Squib Ltd.
            </p>
          </div>

          <div className="second-terms-cont">
            <h2>Inhalt</h2>
            <ul>
              {listArr.map((item, index) => (
                <li key={item.id}>
                  <a href="#" onClick={e => scrollToSection(index, e)}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              {/*<li>
                <a href="">1. Vertrag und Vertragswiderruf</a>
              </li>
              <li>
                <a href="">2. Gebühren und Zahlungen</a>
              </li>
              <li>
                <a href="">3. Datenschutz</a>
              </li>
              <li>
                <a href="">4. Ihre Inhalte</a>
              </li>
              <li>
                <a href="">5. Geistiges Eigentum von Squib</a>
              </li>
              <li>
                <a href="">6. Ressourcen Dritter</a>
              </li>
              <li>
                <a href="">7. Kontoverwaltung</a>
              </li>
              <li>
                <a href="">8. Aussetzung und Beendigung von Services</a>
              </li>
              <li>
                <a href="">9. Änderungen und Aktualisierungen</a>
              </li>
              <li>
                <a href="">10. Haftungsausschlüsse und Entschädigung</a>
              </li>
              <li>
                <a href="">11. Sonstige Bedingungen</a>
              </li>*/}
            </ul>
          </div>

          <div className="third-terms-cont" id="1" ref={el => (sectionsRef.current[0] = el)}>
            <h2>1. Vertrag und Vertragswiderruf</h2>
            <p>
              Der Vertrag über die Services tritt in Kraft, sobald der Benutzer unsere Nutzungsbedingungen akzeptiert und bei
              kostenpflichtigen Services diese bezahlt. Der Vertrag wird automatisch um einen weiteren monatlichen oder jährlichen
              Abrechnungszeitraum verlängert – bis zum Moment, wann der Benuzter dieses automatische Abonnement aufhebt. Diese Aufhebung
              kann jederzeit auf der Kontoverwaltungsseite durchgeführt werden (weitere Informationen in Abschnitt «Gebühren und Zahlungen»
              sowie «Aussetzung und Beendigung von Services»).
            </p>
          </div>

          <div className="fourth-terms-cont" id="2" ref={el => (sectionsRef.current[1] = el)}>
            <h2>2. Gebühren und Zahlungen</h2>
            <div className="fourth-terms__one">
              <h3>Servicegebühren</h3>
              <p>
                Sie willigen ein, sämtliche Gebühren für alle von Ihnen erworbenen oder verwendeten kostenpflichtigen Services gemäss den
                Preisen und Zahlungsbedingungen, die Ihnen von Squib oder dem externen Zahlungsabwickler «Stripe Payments Europe Limited»
                (https://stripe.com/de-ch) für die jeweiligen Services zum Zeitpunkt des Erwerbs durch Sie vorgelegt wurden und die
                gelegentlich gemäss Abschnitt «Preisanpassungen» unten aktualisiert werden können, zu entrichten. Sofern anwendbar, erfolgt
                die Abrechnung unter Verwendung der Abrechnungsmethode, die Sie auf Ihrer Kontoverwaltungsseite ausgewählt haben. Wenn Sie
                entschieden haben, die Gebühr per Kreditkarte zu begleichen, versichern und gewährleisten Sie, dass die von Ihnen
                angegebenen Kreditkartendaten korrekt sind und Sie «Stripe Payments Europe Limited» über etwaige Änderungen unverzüglich in
                Kenntnis setzen werden. Sofern nicht in den vorliegenden Nutzungsbedingungen so vorgesehen oder gesetzlich vorgeschrieben,
                sind von Ihnen gezahlte Gebühren nicht erstattungsfähig.
              </p>

              <div>
                Wir sind nicht verantwortlich für Leistung und Sicherheit von «Stripe Payments Europe Limited» und wir haben keine Kontrolle
                darüber. Sie stimmen zu, alle Gebühren zu bezahlen, die für jeden Kauf im Einklang mit den geltenden Zahlungsbedingungen
                berechtigt sind.
              </div>
            </div>

            <div className="fourth-terms__two">
              <h3>Abonnements</h3>
              <p>
                Einige unserer Services werden auf Abonnementbasis abgerechnet (diese nennen wir «Abonnements» oder «Preispläne»). Es gibt
                jährliche und monatliche Abrechnungszeiträume, je nachdem, für welchen Abonnementtarif Sie sich beim Kauf eines Abonnements
                entscheiden. Ihr Abonnement verlängert sich bei Ablauf eines Abrechnungszeitraums automatisch, es sei denn, Sie kündigen Ihr
                Abonnement über Ihre Kontoverwaltungsseite; in diesem Fall gilt Ihr Abonnement bis zum Ablauf des vor der Kündigung
                begonnenen Abrechnungszeitraums.
              </p>

              <p>
                Wenn Sie Ihr Abonnement kündigen, können Sie bis zum Ende des betreffenden Abrechnungszyklus auf den Service zugreifen und
                Ihre Daten exportieren. Danach haben Sie für dieses Abonnement keinen Zugriff mehr auf den Service. Wenn Sie kein aktives
                Abonnement mehr haben, wird Ihr Konto auf den FREE-Tarif von Squib (kostenlos) umgestellt. Um Ihr Konto zu schliessen und
                Ihren Vertrag mit uns zu kündigen, lesen Sie bitte Abschnitt «Aussetzung und Beendigung von Services» unten.
              </p>

              <p>
                Sie sind für alle Steuern verantwortlich. Sie haften selbst für die Abfuhr von Steuern in Verbindung mit Ihrem Kauf und die
                Richtigkeit Ihrer Rechnungsinformationen.
              </p>
            </div>

            <div className="fourth-terms__three">
              <h3>Preisanpassungen</h3>
              <p>
                Squib ist berechtigt, die für Sie für die Services erhobenen Gebühren jederzeit zu ändern, sofern die Änderung bei Services,
                die auf Abonnementbasis abgerechnet werden, erst zum Ende des laufenden Abrechnungszyklus Ihres Abonnements in Kraft tritt.
                Squib wird Sie vorab über Preisanpassungen informieren. Falls Sie der Gebührenänderung nicht zustimmen, können Sie Ihr
                Abonnement kündigen, bevor die Änderung in Kraft tritt.
              </p>
            </div>

            <div className="fourth-terms__four">
              <h3 className="fourth-terms__four-title">Beantwortungs-Überschreitungsgebühren</h3>
              <h3 className="fourth-terms__four-title-mobile">{fourthTermsFourTitle}</h3>
              <p>
                Für jedes Abonnement gilt ein festes Limit für Antworten. Für das kostenlose Abonnement FREE ist das Limit 50 Teilnehmende
                pro Umfrage, bei den kostenpflichtigen PRO 1&apos;000 Teilnehmende pro Monat und bei BUSINESS 50&apos;000 Teilnehmende pro
                Monat. Falls Sie das Teilnehmenden-Limit für Ihr kostenpflichtiges Abonnement in einem Abrechnungszyklus überschreiten,
                kommt es zu zusätzlichen Kosten pro Teilnehmende (&laquo;Überschreitungsgebühren&raquo;).
              </p>

              <p>
                Sie willigen ein, dass nicht ausgeschöpftes Teilnehmenden-Limit nicht automatisch auf den nächsten Abrechnungszyklus
                angewendet werden. Soweit nicht anders angegeben, werden Ihnen anfallende Überschreitungsgebühren nachträglich über die
                hinterlegte Zahlungsmethode zu Beginn Ihres nächsten Abrechnungszyklus in Rechnung gestellt. Bei Nichtzahlung von
                Überschreitungsgebühren innerhalb von 30 Tagen nach Rechnungslegung tritt Verzug ein. Bei Nichtzahlung der
                Überschreitungsgebühren kann der jeweilige Service (vorbehaltlich geltender gesetzlicher Anforderungen) eingeschränkt,
                ausgesetzt oder gekündigt werden, was je nach geltendem Recht zu einem Verlust der mit jenem Service verbundenen Daten
                führen kann.
              </p>
            </div>
          </div>

          <div className="fifth-terms-cont" id="3" ref={el => (sectionsRef.current[2] = el)}>
            <h2>3. Datenschutz</h2>
            <div className="fifth-terms__one">
              <h3>Datenschutz</h3>
              <p>
                Uns ist bewusst, dass die Übermittlung Ihrer Inhalte (wie unten definiert) an uns mit dem Vertrauen in uns verbunden ist,
                mit den Inhalten angemessen umzugehen. Die Datenschutzerklärung von Squib und seiner Services geht darauf ein, wie wir mit
                Ihren Inhalten umgehen, die als personenbezogene Daten betrachtet werden können (gemäss Definition in unserer
                Datenverarbeitungsvereinbarung), und wir stimmen zu, uns an diese Datenschutzerklärungen von Squib zu halten. Sie stimmen
                wiederum zu, dass Squib Ihre Inhalte gemäss der Squib-Datenschutzerklärung und den geltenden gesetzlichen
                Datenschutzbestimmungen nutzen und freigeben darf. Sie stimmen ferner zu, dass Sie für die Benachrichtigung Ihrer Befragten
                über die Datenschutzerklärungen von Squib verantwortlich sind. Im Falle eines Widerspruchs zwischen der
                Datenverarbeitungsvereinbarung und diesen Bedingungen hat die Datenverarbeitungsvereinbarung Vorrang, mit Ausnahme des
                Haftungsausschlusses und der Haftungsbeschränkung, für die die vorliegenden Bedingungen massgeblich sind.
              </p>
            </div>

            <div className="fifth-terms__two">
              <h3>Vertraulichkeit</h3>
              <p>
                Squib behandelt Ihre Inhalte als vertrauliche Informationen und wird diese nur gemäss den vorliegenden Nutzungsbedingungen
                (einschliesslich der Squib-Datenschutzerklärungen) nutzen und offenlegen. Ihre Inhalte gelten allerdings nicht als
                vertrauliche Information, wenn: a) sie ohne Verstoss gegen diese Bedingungen seitens Squib öffentlich bekannt werden oder
                wurden, b) sie Squib über rechtmässige Wege bereits bekannt waren, bevor sie von Ihnen unterbreitet wurden, c) Squib die
                Inhalte von Dritten erhält, ohne dabei Kenntnis von Pflichtverletzungen Ihnen gegenüber zu haben, d) Ihre Inhalte bei der
                Migration Ihres Kontos in ein Enterprise-Konto einer Organisation freigegeben werden, wenn Ihr Konto mit einer
                Arbeits-E-Mail-Adresse von dieser Organisation registriert wurde oder e) Sie ohne Bezugnahme auf Ihre Inhalte von Squib
                eigenständig entwickelt wurden. Squib ist berechtigt, Ihre Inhalte im Rahmen rechtlicher oder gesetzlicher Pflichten
                offenzulegen, jedoch erst, nachdem Squib im Rahmen gesetzlicher Vorgaben wirtschaftlich angemessene Massnahmen unternommen
                hat, um Sie in Kenntnis zu setzen und es Ihnen so zu ermöglichen, die Pflicht zur Offenlegung anzufechten.
              </p>
            </div>

            <div className="fifth-terms__three">
              <h3>Sicherheit</h3>
              <p>
                Squib speichert und verarbeitet Ihre Inhalte auf eine den allgemeinen Sicherheitsstandards konforme Weise. Squib hat
                geeignete technische, organisatorische und administrative Systeme, Richtlinien und Verfahren implementiert.
              </p>
              <p>
                Wenn Squib von Zugriff auf oder von Erwerb, Nutzen, Veränderung, Offenlegung oder Vernichtung personenbezogener Daten
                (Definition gemäss unserer Vereinbarung zur Datenverarbeitung) in Bezug auf Ihr Konto, die auf unbefugte oder
                widerrechtliche Weise erfolgen, Kenntnis erhält («Sicherheitsvorfall»), ergreift Squib angemessene Massnahmen, um Sie
                unverzüglich davon zu unterrichten. Eine solche Benachrichtigung darf nicht als Zugeben von Fehlern oder Übernahme von
                Haftung durch Squib interpretiert oder ausgelegt werden. Ein Sicherheitsvorfall umfasst keine erfolglosen Anmeldeversuche,
                Pings, Port-Scans, Denial-of-Service-Angriffe oder anderen Netzwerkangriffe auf Firewalls oder vernetzte Systeme. Squib wird
                auch im angemessenen Umfang mit Ihnen bei möglichen Untersuchungen zu Sicherheitsvorfällen kooperieren, die erforderlichen
                Mitteilungen und Informationen im angemessenen Umfang nach Anfrage von Ihnen bereitstellen, sofern sie uns in Bezug auf
                einen Sicherheitsvorfall vorliegen, wenn solche Informationen Ihnen nicht direkt in Ihrem Konto oder online mittels von
                Squib bereitgestellten Updates zur Verfügung stehen.
              </p>
            </div>
          </div>

          <div className="sixth-terms-cont" id="4" ref={el => (sectionsRef.current[3] = el)}>
            <h2>4. Ihre Inhalte</h2>
            <div className="sixth-terms__one">
              <h3>Eigentum Ihrer Inhalte</h3>
              <p>
                Im Laufe der Nutzung der Services können Sie Inhalte an Squib übermitteln (einschliesslich personenbezogener Daten von Ihnen
                und anderen) und Dritte können über die Services an Sie Inhalte übermitteln (alles vorstehend Genannte wird als Ihre
                «Inhalte» bezeichnet). Sie behalten das Eigentum an allen Urheberrechten an Ihren Inhalten. Squib macht bezüglich Ihrer
                Inhalte keine Eigentumsrechte geltend. Die vorliegenden Nutzungsbedingungen gewähren uns keinerlei Lizenzen und Rechte an
                Ihren Inhalten ausser der eingeschränkten Lizenz, die in diesen Bedingungen festgelegt ist. Wenn Sie also unsere Services
                nutzen, behalten Sie, was Ihnen gehört, und erlauben uns, es nur so zu nutzen, wie es notwendig ist, um unsere Services
                weiterhin bereitzustellen und zu verbessern, wie in unseren Datenschutzerklärungen angegeben.
              </p>
            </div>
            <div className="sixth-terms__two">
              <h3>Zusicherungen und Garantien</h3>
              <p>
                Sie sichern zu und garantieren, dass: (a) Sie die entsprechenden Rechte an Ihren Inhalten besitzen oder kontrollieren,
                einschliesslich des geistigen Eigentums Dritter; und (b) Sie keine Inhalte oder Materialien absenden, hochladen oder
                anderweitig über die Services zur Verfügung stellen werden, welche den allgemeingültigen Richtlinien zur zulässigen Nutzung
                verletzen. Dies beinhalten Hassreden, Mobbing, Diskriminierung jeglicher Minderheiten, Belästigung und kriminelle
                Aktivitäten, Pornografie, anstössige Bilder sowie Verletzungen des geistigen Eigentums.
              </p>
            </div>

            <div className="sixth-terms__three">
              <h3>Zuständigkeit für Ihre Inhalte</h3>
              <p>
                Sie sind für Ihre Inhalte verantwortlich. Wir sind nicht dafür verantwortlich, was Sie mit Ihren Inhalten machen, und können
                uns weigern, Ihre Inhalte anzuzeigen, wenn wir der Auffassung sind, dass diese illegal sind oder unsere Bedingungen
                verletzen. Dem geltenden Recht entsprechend haftet Squib nicht für Ihre Inhalte, andere Inhalte oder Materialien von Dritten
                oder für Verluste oder Schäden, die sich aus der Verwendung dieser Inhalte oder anderer Inhalte oder Materialien von Dritten
                oder aus dem Vertrauen auf diesen ergeben.
              </p>
            </div>
          </div>

          <div className="seventh-terms-cont" id="5" ref={el => (sectionsRef.current[4] = el)}>
            <h2>5. Geistiges Eigentum von Squib</h2>

            <p>
              Weder durch diese Bedingungen noch durch die Nutzung der Services werden Ihnen Eigentumsrechte an den Services oder den
              Inhalten, auf die Sie über die Services zugreifen, eingeräumt. Davon ausgenommen sind Ihre eigenen Inhalte. Wenn Sie uns zu
              unseren Services Feedback oder Vorschläge zukommen lassen, sind wir berechtigt, diese ohne Vergütung an Sie und ohne
              Verpflichtung Ihnen gegenüber für beliebige Zwecke zu verwenden.
            </p>
          </div>

          <div className="eighth-terms-cont" id="6" ref={el => (sectionsRef.current[5] = el)}>
            <h2>6. Ressourcen Dritter</h2>
            <p>
              Squib ist berechtigt, Links zu Internet-Websites, die von Dritten unterhalten werden, auf seinen Services zu veröffentlichen.
              Squib gewährleistet nicht, dass es solche Drittanbieter-Websites geprüft hat, und ist weder für sie noch für die dort
              erscheinenden Inhalte verantwortlich. Marken, die in Verbindung mit den Services angezeigt werden, sind Eigentum ihrer
              jeweiligen Inhaber.
            </p>
          </div>

          <div className="ninth-terms-cont" id="7" ref={el => (sectionsRef.current[6] = el)}>
            <h2>7. Kontoverwaltung</h2>
            <div className="ninth-terms__one">
              <h3 className="ninth-terms__one-title">Passwort-, Email- und Kontodatensicherheit</h3>
              <h3 className="ninth-terms__one-title-mobile">{ninthTermsOneTitle}</h3>
              <p>
                Wir arbeiten kontinuierlich daran, die Sicherheit Ihres Kontos aufrechtzuerhalten. Sie müssen ein Kundenkonto mit einem
                sicheren Passwort erstellen, um unsere Services zu nutzen. Geben Sie Passwörter niemals weiter. Denn Sie und nicht Squib
                sind für alle in Ihrem Konto stattfindenden Aktivitäten (mit Ausnahme solcher Aktivitäten, für die Squib direkt
                verantwortlich ist und die nicht auf Ihre Anweisung hin ausgeführt werden) unabhängig davon verantwortlich, ob solche
                Aktivitäten von Ihnen genehmigt wurden oder nicht.
              </p>
              <p>
                Halten Sie auch Ihre E-Mail- und Kontodaten stets auf dem aktuellen Stand. Squib versendet bisweilen Hinweise an die für Ihr
                Konto registrierte E-Mail-Adresse. Es obliegt Ihnen, Ihre E-Mail-Adresse und ggf. weitere mit Ihrem Konto verknüpfte
                Kontakt- und Zahlungsdaten korrekt und auf dem aktuellen Stand zu halten. Sie gewährleisten, dass Ihre Kontodetails richtig
                sind.
              </p>
            </div>

            <div className="ninth-terms__two">
              <h3>Kontoinaktivität</h3>
              <p>
                Squib ist berechtigt, Ihr Konto zu schliessen und alle darin enthaltenen Inhalte zu löschen, sofern für mehr als 12 Monate
                keinerlei Kontoaktivitäten (z. B. Anmeldeereignisse oder Zahlungen) stattgefunden haben. Wir werden, falls angemessen, vor
                dem Schliessen Ihres Kontos wegen Inaktivität versuchen, Sie per E-Mail vorab zu warnen, um Ihnen die Möglichkeit zu bieten,
                sich bei Ihrem Konto anzumelden, damit dieses aktiv bleibt.
              </p>
            </div>
          </div>

          <div className="tenth-terms-cont" id="8" ref={el => (sectionsRef.current[7] = el)}>
            <h2>8. Aussetzung und Beendigung von Services</h2>
            <div className="tenth-terms__one">
              <h3>Durch Sie</h3>
              <p>
                Sie können Ihr Abonnement jederzeit über die Kontoverwaltungsseite kündigen. Wir bieten keine Rückerstattungen bereits
                getätigter Zahlungen. Die Ausnahme ist, dass die Kündigung auf einer wesentlichen Vertragsverletzung durch uns beruht oder
                eine Erstattung ist gesetzlich vorgeschrieben ist. Wir behalten uns das Recht vor, 30 Tage nach Beendigung Ihres Abonnements
                alle Ihre Daten inklusive aller Umfragen und Umfrageergebnisse ohne Ankündigung zu löschen.
              </p>
            </div>

            <div className="tenth-terms__two">
              <h3>Durch Squib</h3>
              <ul>
                <li>
                  <div className="tenth-tems__list-item list-item-1">
                    <span>1.</span>
                    <p>
                      Squib kann Ihr Abonnement ohne Rückerstattung für einen früheren Zeitraum mit Wirkung vom Ende eines Abrechnungszyklus
                      kündigen, wobei Sie mindestens 30 Tage im Voraus schriftlich benachrichtigt werden.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="tenth-tems__list-item list-item-2">
                    <span>2.</span>
                    <p>
                      Squib ist berechtigt, Ihr Abonnement aus einem der folgenden Gründe zu beenden: I) Sie haben wesentlich gegen die
                      vorliegenden Bedingungen verstossen, II) Sie zahlen Entgelte nicht spätestens 30 Tage nach Fälligkeitsdatum, III) Sie
                      nutzen die Services auf eine Weise, aus der Haftungsansprüche gegen uns erwachsen oder die die Nutzung der Services
                      Dritte unterbindet oder IV) wir sind durch geltendes Recht gezwungen, dies zu tun. Ferner werden wir uns, wenn wir Ihr
                      Abonnement deaktivieren oder kündigen und/oder die Services einstellen, je nach Grund bemühen, Ihnen vorab eine
                      entsprechende Mitteilung zukommen zu lassen und die Möglichkeit zu gewähren, eine Kopie Ihrer Inhalte aus diesem
                      Service zu exportieren, Es können allerdings dringende Situationen eintreten, in denen Squib sich für unverzügliche
                      Massnahmen ohne vorherige Benachrichtigung entscheidet.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="tenth-tems__list-item list-item-3">
                    <span>3.</span>
                    <p>
                      Die Umfragen und die Ergebnisse bleiben für PRO-Kunden zwei Monate und für BUSINESS-Kunden vier Jahre gespeichert und
                      abrufbar. Im Falle einer Insolvenz von Squib Ltd. behalten wir uns das Recht vor, diese Zugriffsfristen anzupassen. In
                      einem solchen Szenario verpflichtet sich Squib, betroffene Kunden mindestens 30 Tage im Voraus schriftlich zu
                      informieren.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="eleventh-terms-cont" id="9" ref={el => (sectionsRef.current[8] = el)}>
            <h2>9. Änderungen und Aktualisierungen</h2>
            <div className="eleventh-terms__one">
              <h3>Änderungen an Bedingungen und Services</h3>
              <p>
                Squib kann diese Bedingungen jederzeit aus vielerlei Gründen ändern, z.B. um Änderungen der geltenden Gesetze oder
                Aktualisierungen der Services widerzuspiegeln und neue Services oder Funktionen zu berücksichtigen, aus Sicherheitsgründen
                oder zur Vermeidung von Missbrauch oder Schaden. Daher ist Squib berechtigt, einem Service, den es Ihnen bereitstellt,
                jederzeit und ohne vorherige Ankündigung Funktionen hinzuzufügen oder im Service vorhandene Funktionen zu ändern. Squib
                behält sich ferner das Recht vor, einen Service, den es Ihnen bereitstellt, nach eigenem Ermessen einzuschränken,
                auszusetzen oder einzustellen. Wenn Squib einen Service einstellt, werden wir Sie in angemessener Frist vorab darüber
                informieren, um Ihnen die Möglichkeit zu gewähren, eine Kopie Ihrer Inhalte aus diesem Service zu erhalten.
              </p>
            </div>

            <div className="eleventh-terms__two">
              <h3>Herabstufungen</h3>
              <p>
                Durch ein Downgrade Ihres Kontotarifs (Kündigen eines Abonnements oder Downgrade auf unseren kostenlosen Preisplan) kann es
                zum Verlust der Inhalte, Funktionen, Funktionalität oder Kapazität Ihres Kontos kommen.
              </p>
            </div>
          </div>

          <div className="twelfth-terms-cont" id="10" ref={el => (sectionsRef.current[9] = el)}>
            <h2>10. Haftungsausschlüsse und Entschädigung</h2>
            <div className="twelfth-terms__one">
              <h3>Haftungsausschluss</h3>
              <p>
                Squib strebt danach, Ihnen stets ein optimales Nutzungserlebnis unserer Online-Services zu bieten, die allerdings aus
                verschiedenen Gründen temporär ausfallen können. Im Rahmen des geltenden Rechts und sofern nicht anders in diesen
                Bedingungen festgelegt, bieten wir die Services und zugehörige Beratungen ohne jegliche ausdrücklichen, stillschweigenden
                oder gesetzlichen Garantien, einschliesslich, aber nicht beschränkt auf, Garantien der Marktgängigkeit, Zweckeignung oder
                Nichtverletzung, sowie Verfügbarkeit, Zuverlässigkeit oder Genauigkeit. Innerhalb der gesetzlichen Zulässigkeit übernimmt
                Squib keine Haftung für indirekte, Folge-, spezielle, zufällige oder strafbewehrte Schäden oder Verluste, einschliesslich
                Nutzungsausfälle, Datenverlust, entgangene Geschäfte oder Gewinne, die durch die Nutzung der Services oder gemäss diesen
                Nutzungsbedingungen entstehen, unabhängig von der Rechtsgrundlage, selbst wenn auf die Möglichkeit solcher Schäden
                hingewiesen wurde.
              </p>
            </div>

            <div className="twelfth-terms__two">
              <h3>Entschädigung</h3>
              <p>
                Wenn es sich bei Ihnen um ein Unternehmen handelt, stellen Sie Squib (einschliesslich seiner und deren Führungskräfte,
                Vertretende und Mitarbeitende) von jeglicher Haftung, Schadensersatz und Kosten (einschliesslich Abwicklungskosten und
                zumutbarer Anwaltskosten) frei, die sich aus Ansprüchen Dritter in Bezug auf oder in Verbindung mit der Nutzung der Services
                oder durch einen Verstoss gegen die vorliegenden Nutzungsbedingungen durch Sie oder Ihre Endbenutzer ergeben, insoweit diese
                Haftungen, Schäden und Kosten durch Sie oder Ihre Endbenutzer verschuldet wurden.
              </p>
            </div>
          </div>

          <div className="thirteenth-terms-cont" id="11" ref={el => (sectionsRef.current[10] = el)}>
            <h2>11. Sonstige Bedingungen</h2>
            <div className="thirteenth-terms__one">
              <h3>Abtretung</h3>
              <p>
                Eine Abtretung dieser Bedingungen ohne die vorherige schriftliche Zustimmung von Squib, die nach alleinigem Ermessen von
                Squib vorenthalten werden darf, ist Ihnen nicht gestattet. Squib kann diese Bedingungen jederzeit ohne Erteilung einer
                Nachricht an Sie abtreten.
              </p>
            </div>

            <div className="thirteenth-terms__two">
              <h3>Eigenständige Vertragsnehmende</h3>
              <p>
                Sie und Squib führen das Verhältnis voneinander unabhängiger Vertragsnehmenden, und kein Vertragspartner hat den Status
                eines Teilhabers, Mitarbeiters oder Handlungsbevollmächtigten des jeweils anderen Vertragspartners.
              </p>
            </div>

            <div className="thirteenth-terms__three">
              <h3>Auslegung</h3>
              <p>
                Die Begriffe «umfassen», «einschliesslich», «wie beispielsweise» und ähnliche Begriffe sind nicht als Einschränkung weiterer
                Inhalte auszulegen.
              </p>
            </div>

            <div className="thirteenth-terms__four">
              <h3>Sprache</h3>
              <p>
                Die vorliegenden Bedingungen wurden in der deutschen Sprache ausgestaltet und verfasst. Soweit übersetzte Fassungen der
                deutschen Fassung widersprechen, ist die deutsche Fassung in dem nach geltendem Recht zulässigen Ausmass rechtskräftig.
              </p>
            </div>

            <div className="thirteenth-terms__five">
              <h3>Kein Verzicht</h3>
              <p>
                Wenn ein Vertragspartner auf die Durchsetzung einer Bestimmung in diesen Bedingungen verzichtet oder sie mit zeitlicher
                Verzögerung ausübt, gilt dies nicht als Verzicht auf seine Rechte zur späteren Durchsetzung.
              </p>
            </div>

            <div className="thirteenth-terms__six">
              <h3>Salvatorische Klausel</h3>
              <p>
                Wenn eine Regelung in diesen Bedingungen von einem zuständigen Gericht als nicht durchsetzbar erklärt wird, gilt die
                Regelung als aus diesen Bedingungen ausgegliedert und die anderen Regelungen bleiben vollständig bestandskräftig.
              </p>
            </div>

            <div className="thirteenth-terms__seven">
              <h3>Drittbegünstigte</h3>
              <p>Drittbegünstigte dieser Bedingungen existieren nicht.</p>
            </div>

            <div className="thirteenth-terms__eight">
              <h3>Beta-Services</h3>
              <p>
                Squib ist berechtigt, bestimmte Produkte oder Features als Test-, Alpha-, Pre-Beta- oder Beta-Version für den vorzeitigen
                Zugriff oder als Sammelbegriff Beta-Version («Beta-Service») zu gestalten oder anzubieten. Wenn Sie einen Beta-Service
                nutzen, gelten zusätzlich die folgenden Bedingungen:
              </p>
              <p>
                Ein Beta-Service wird zum Testen und zur Evaluierung angeboten. Er stellt nicht das endgültige Produkt oder den endgültigen
                Service dar und kann Programmfehler enthalten, die zu Systemausfällen oder sonstigen Störungen, Fehlern und Datenverlusten
                führen können. Squib entscheidet sich evtl. dazu, keine kommerzielle Version des Beta-Service freizugeben. Squib kann nach
                eigenem Ermessen und jederzeit den Beta-Service entfernen oder absetzen und eine kommerzielle Version des Beta-Service
                freigeben. Sie müssen die Nutzung des Beta-Service unverzüglich einstellen, wenn wir Sie dazu auffordern. Im Gegenzug für
                die Nutzung eines Beta-Service erklären Sie sich damit einverstanden, dass Squib Daten bezüglich Ihrer Nutzung des
                Beta-Service einschliesslich Feedback erfasst und Ihre Inhalte analysieren (einschliesslich manueller Überprüfungen) kann,
                um den Service zu verbessern und Fehler zu beheben. Falls Sie nicht möchten, dass dieser Nutzungs- oder Feedbackdaten
                erfasst oder der Inhalt analysiert wird, müssen Sie die Nutzung des Beta-Service unverzüglich einstellen.
              </p>
              <p>
                Squib erteilt keinerlei Zusicherungen oder Garantien in Bezug auf Beta-Services, darunter keine Garantie, dass ein
                Beta-Service allgemein verfügbar sein wird, unterbrechungs- oder fehlerfrei funktionieren wird oder dass Ihre Inhalte sicher
                sind oder nicht verloren gehen oder nicht beschädigt werden. Squib übernimmt keine Haftung, die sich aus oder in Verbindung
                mit einem Beta-Service ergeben. Jegliche Nutzung eines Beta-Service durch Sie erfolgt auf Ihr eigenes Risiko und nach Ihrem
                eigenen Ermessen.
              </p>
            </div>
          </div>
        </div>

        <GetInTouchTermsOfUse />
      </div>
    </>
  );
};

export default TermsOfUse;
