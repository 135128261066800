import React from "react";

interface IEuropeUnionDataRightsProps {
  headerTitle?: string;
  firstParagraph?: string;
  secondParagraph?: string;
  thirdParagraph?: string;
  fourthParagraph?: string;
  fifthParagraph?: string;
  sixthParagraph?: string;
  headerContent1?: string;
  headerContent2?: string;
  headerContent3?: string;
  headerContent4?: string;
  headerContent5?: string;
  headerContent6?: string;
  content1?: string;
  content2?: string;
  content3?: string;
  content4?: string;
  content3Points?: string[];
  point?: (string | JSX.Element)[];
  point2?: (string | JSX.Element)[];
}

const points = ["a.", "b.", "c.", "d.", "e.", "f.", "g.", "h."];

const EuropeUnionDataRights = (props: IEuropeUnionDataRightsProps) => {
  return (
    <div className="europe-union-data-container" style={{ width: "912px" }}>
      <div className="europe-union-data-wrapper">
        <h2 className="header-rights-title">{props.headerTitle}</h2>
        <p className="first-paragraph">{props.firstParagraph}</p>
        <ul>
          <div>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent1}</span>: {props.content1}
            </li>
            <div className="first-points-wrapper">
              {props.point && (
                <>
                  {props.point.map((item: any, index: number) => (
                    <p className="first-points-content" key={index}>
                      {points[index]} {item}
                    </p>
                  ))}
                </>
              )}
            </div>
            <p>{props.secondParagraph}</p>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent2}</span>: {props.content2}
            </li>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent3}</span>: {props.content3}
              <div className="content3-list-wrapper">
                {props.content3Points && (
                  <>
                    {props.content3Points.map((item: any, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </>
                )}
              </div>
            </li>
            <p>{props.thirdParagraph}</p>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent4}</span>: {props.content4}
              <div className="second-points-wrapper">
                {props.point2 && (
                  <>
                    {props.point2.map((item: any, index: number) => (
                      <p key={index}>
                        {points[index]} {item}
                      </p>
                    ))}
                  </>
                )}
              </div>
            </li>
            <p>{props.fourthParagraph}</p>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent5}</span>: {props.fifthParagraph}
            </li>
            <li style={{ margin: "1rem" }}>
              <span className="data-rights-header-content">{props.headerContent6}</span>: {props.sixthParagraph}
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default EuropeUnionDataRights;
