import React from "react";
import "../../../../../resources/styles/components/common/testimonials-layout.css";

interface LayoutProps {
  title?: string;
  description?: string;
  websiteLink?: string;
  websiteLinkClass?: string;
  authorName?: string;
  authorTitle?: string;
  brandLogo?: string;
  classNameTwo?: string;
  avatar?: string;
  membersInfoClass?: string;
}

const TestimonialsLayout = (props: LayoutProps) => {
  return (
    <div className="members-container">
      <div className="members-wrapper">
        <div className="members-brand-logo">
          <img src={props.brandLogo} alt="brand logo" className={`members-brand-logo`} />
        </div>
        <div className="members-cont">
          <p className="members-desc">{props.description}</p>
          <div className={`members-website-link ${props.websiteLinkClass}`}>
            <a href={`https://${props.websiteLink}`}>{props.websiteLink}</a>
          </div>
          <div className="members-author">
            <div className="members-avatar-wrapper">
              <img src={props.avatar} alt="avatar" className="members-avatar" />
            </div>
            <div className={`members-info ${props.membersInfoClass}`}>
              <p>{props.authorName}</p>
              <p>{props.authorTitle}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsLayout;
