export const german = {
  home: "Home",
  createVote: "Voting erstellen",
  explore: "Squib entdecken",
  useCases: "Anwendungsbeispiele",
  pricingFeatures: "Preise",
  successStories: "Erfolgsgeschichten",
  comparison: "Squib im Vergleich",
  security: "Sicherheit",
  integrations: "Integrationen",
  releases: "Releases",
  imprintPrivacy: "Impressum & Datenschutz",
  faqHelp: "FAQ & Hilfe",
  voting: "Voting",
  votingDeleted: "Voting gelöscht",
  votingClosed: "Voting geschlossen",
  about: "Über uns",
  contact: "Kontakt",
  balance: "Guthaben",
  error: "Fehler",

  comparisonUrl: "...",
  comparisonFooter: "...",

  voteForA: "...",
  voteForB: "...",

  voteForThis: "...",
  voteForThat: "...",

  homeHeaderTitle: "Simplify your surveys.",
  homeHeaderDesc: "Die einfachste Art, attraktive Umfragen zu erstellen, die alle deine Zielgruppen lieben werden.",

  errorWaitingListMessage: "Bitte gib eine korrekte E-Mail-Adresse ein.",

  startUpNightsParagraph:
    "«Mit Squib konnten wir unmittelbar während des Events den Puls der Teilnehmenden spüren. Dank des smarten Umfragedesigns erzielten wir eine tolle Rücklaufquote und besonders authentisches Feedback.»",
  benedictParagraph:
    "«Als fortschrittliche Schule ist es uns wichtig, auf die Bedürfnisse der Lernenden einzugehen. Mit Squib konnten wir besonders authentisches und unmittelbares Feedback von den Lernenden einholen. Squib bereitet die Resultate einfach auf, sodass wir sehr effizient die Schlüsse daraus ziehen können.»",
  useCasesTitle: "Anwendungsbeispiele",
  useCasesTitleMobile: "Anwendungs-\nbeispiele",
  useCasesSubTitle:
    "Längerfristiger Erfolg ist für Organisationen nur dann erreichbar, wenn sie kontinuierlich den Bedürfnissen ihrer Stakeholder Gehör schenken. Hier findest du einige Beispiele aus dem Alltag verschiedener Organisationen, die Squib erfolgreich einsetzen.",

  cardOneUseCasesTwoText: "...",
  cardThreeUseCasesText: "...",

  getInTouchUseCasesHeader: "Möchtest du Squib nutzen oder hast du eine Frage?",
  getInTouchUseCasesBtn: "Jetzt kontaktieren",

  successStoriesTitle: "...",
  successStoriesSubTitle: "...",

  getInTouchSuccessStoriesHeader: "...",
  getInTouchSuccessStoriesBtn: "...",

  clientNeedsSubtitle:
    "«Mit Squib konnten wir unmittelbar während des Events den Puls der Teilnehmenden spüren. Dank des smarten Umfragedesigns erzielten wir eine tolle Rücklaufquote und besonders authentisches Feedback.»",
  clientNeedsParagraph: "www.startup-nights.com",
  clientNeedsAuthor: "Alyssia Kugler",
  clientNeedsAuthorTitle: "Director",

  pricingFeaturesTitle: "...",
  pricingFeaturesSubTitle: "...",
  appVersionHeader1: "...",
  appVersionHeader2: "...",
  appVersionHeader3: "...",
  appVersionsDesc: "...",
  appVersionBtn1: "...",
  appVersionBtn2: "...",
  freeRegistration: "...",
  pricePro: "...",
  priceProPlus: "...",

  components: "Komponenten",

  whySquib: "Warum Squib",
  feedback: "Feedback macht Spass",
  exploreHeaderTitle: "Weshalb Squib?",
  exploreHeaderDesc:
    "Mit Squib kriegst du mehr und authentischer Online-Feedback als mit anderen Tools. Durch die äusserst benutzerfreundliche und einfache Handhabung fördert Squib die Beteiligung deiner Zielgruppen aller Altersklassen. Empirische Untersuchungen belegen dies mehrfach.",

  exploreText: "Weil gute Umfragen auch Spass machen können.",
  exploreSubText:
    "Es ist erwiesen, dass Umfragen mehr Beteiligung erhalten, wenn sie optisch ansprechend und einfach zu handhaben sind. Squib verfolgt die Mission, Organisationen dabei zu unterstützen, eine echte Kultur des Feedbacks zu leben. Denn heutzutage wird viel kommuniziert, jedoch wird den individuellen Bedürfnissen oft zu wenig Aufmerksamkeit geschenkt.",
  whySquibFeedback: "Weshalb Squib begeistert:",

  propertyOne: "Keep it simple",
  propertyTwo: "Ehrlicheres Feedback",
  propertyThree: "Übersichtliche Auswertungen",
  propertyFour: "Verlässliche Daten",
  propertyOneSub:
    "In einer Welt, in der Aufmerksamkeit ein kostbares Gut ist, lohnt es sich, auf bewährte und intuitiv verständliche Methoden zu setzen, die bei den Menschen Anklang finden, statt sich in einer Vielzahl unterschiedlicher Fragetypen zu verlieren.",
  propertyTwoSub:
    "Das intuitive Design lädt ein, Squib auch unterwegs oder bei Wartezeiten zu nutzen. Dies erhöht das Engagement und führt gleichzeitig zu authentischeren Antworten.",
  propertyThreeSub:
    "Die Ergebnisse werden automatisch für dich aufbereitet, sodass du direkt loslegen kannst. Dank fortschrittlicher KI-Technologie wird es bald möglich sein, qualitative Rückmeldungen zu gruppieren, zusammenzufassen oder in erweiterte Zusammenhänge zu stellen.",
  propertyFourSub:
    "Wähle einfach aus, ob deine Umfrage öffentlich zugänglich oder exklusiv für einen bestimmten Personenkreis bestimmt ist. Diese Flexibilität gewährleistet eine verlässliche und sichere Datenerhebung.",
  advantageTitle: "Vorteile, die du mit Squib hast:",

  advantageOne: "Einfaches und intuitives Handling, kein Handbuch und keine Einarbeitung nötig.",
  advantageTwo: "Individualisierung mit eigenem Logo und Styling, ganz nach deinen Bedürfnissen.",
  advantageThree: "Gamification: Spielerische Elemente sorgen für hohe Beteiligungsquote und minimale Abbruchrate.",
  advantageFour: "Überzeugende User Experience, ohne dass ein App-Download notwendig ist.",
  funcQuestion: "Möchtest du Squib nutzen oder hast du eine Frage?",
  funcCallToActionText: "Jetzt kontaktieren",
  anyQuestion: "Haben Sie Fragen oder brauchen Sie Hilfe?",
  getInTouchBtn: "Nehmen Sie jetzt Kontakt auf!",

  ctaText: "...",
  subText: "...",

  groupOneTitle1: "Warum sind klassische Umfragen unbeliebt?",
  groupOneDesc1:
    "Klassische Befragungen sind oft anstrengend und bieten wenig Motivation zur Teilnahme. Squib revolutioniert diesen Prozess durch eine intuitive und empirisch geprüfte Methode, die besonders authentische Antworten und eine hohe Beteiligung fördert. Das Besondere (und Einmalige) dabei: Die Menschen nehmen gerne teil!",
  btnGroupOne1: "Mehr erfahren",

  versionsTitle: "Willst du zu den Ersten gehören, die Squib nutzen und von Vorzugskonditionen profitieren können?",
  versionsDescHome: "Trage dich jetzt in die Warteliste ein und erhalte laufend exklusive News zu Squib.",
  inputWaitingList: "Deine E-Mail-Adresse",
  btnWaitingList: "Hier eintragen",
  waitingListConfirm: "Danke!",
  waitingListConfirmSubText: "Wir halten dich auf dem Laufenden.",

  btnVersions: "...",

  startupCallToActionHeader: "Möchtest du Squib nutzen oder hast du eine Frage?",
  btnStartupCallToAction: "Jetzt kontaktieren",

  groupOneTitle2: "Weniger Aufwand, mehr Feedback!",
  groupOneDesc2:
    "Squib erhöht die Aufmerksamkeit deiner Zielgruppen durch attraktives Design. Authentisches Feedback erreichst du mit der einfachen, zugleich cleveren Usability. Gefordert bist du nur, deine Anspruchsgruppen auch regelmässig einzubinden.",
  btnGroupOne2: "Beispiele ansehen",

  btnStartup: "...",

  question: "...",
  questionInp: "...",
  hashtagInp: "...",
  linkTerms: "...",
  linkSub: "...",
  hintText: "...",

  useCasesTitleOne: "Bedürfnisorientierter Schulunterricht etablieren",
  useCasesDescOne:
    "Ein ideales Lernumfeld stellt die Bedürfnisse der Lernenden stets in den Fokus. Wesentlich ist dabei, regelmässig und fortlaufend während des Semesters Feedback zu sammeln – zeitgemäss und interaktiv, direkt über einen QR-Code im Klassenzimmer.",

  brandHeader: "Dieses Beispiel wurde realisiert mit",

  useCasesTitleTwo: "Bedürfnisse der Mitarbeitenden berücksichtigen",
  useCasesDescTwo:
    "Den Mitarbeitenden zuhören und diese partizipieren zu lassen, ist ein wichtiger Erfolgsfaktor einer Organisation. Dies sollte jedoch niederschwellig und intuitiv geschehen, entweder direkt am Arbeitsplatz via QR-Code oder zeit- und ortsunabhängig via Link.",

  useCasesTitleThree: "Event-Feedback in Echtzeit",
  useCasesDescThree:
    "Erreiche an deinem Event die Besuchenden direkt und auf eine clevere Art und Weise, um wertvolles Feedback zu den Themen zu sammeln, die dich weiterbringen. Diese innovative Form der Interaktion ermöglicht es auch den Teilnehmenden, aktiv an den Weichenstellungen für die Zukunft mitzuwirken.",

  useCasesTitleFour: "Co-Creation: Kunden in Entwicklungen miteinbeziehen",
  useCasesDescFour:
    "Eine neue Art von aktiver Einbindung der (künftigen) Kunden ermöglicht es, Produkte und Dienstleistungen genau an deren Bedürfnissen und Wünschen auszurichten: «Marktforschung light» kombiniert mit cleverem Marketing.",

  useCasesFirstCardHeader: "...",
  useCasesSecondCardHeader: "...",
  useCasesThirdCardHeader: "...",
  useCasesFourthCardHeader: "...",

  votingMainTitle: "...",
  linkOptionTitle: "...",
  dataText: "...",
  dataDesc: "...",

  contactTitle: "Kontakt",
  contactSubTitle:
    "Möchtest du Squib nutzen? Hast du eine Frage oder möchtest du uns einfach ein Feedback geben? Kontaktiere uns per E-Mail oder LinkedIn. Wir freuen uns!",
  contactPlace: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSchweiz",

  checkText1: "Ich möchte Squib nutzen (Pro, Pro+ oder Enterprise)",
  checkText2: "Ich habe eine Frage oder möchte ein Feedback geben",
  checkText3: "Ich möchte für Squib arbeiten",
  checkText4: "Ich bin Investor und bin an eurem Business-Case interessiert",

  company: "Firmenname eingegeben",
  name: "Nachname eingegeben",
  lastname: "Vorname eingegeben",
  telephone: "Telefonnummer eingegeben",
  message: "Mittteilung eingegeben",
  oblig: "erforderlich",

  labelCompany: "Firma",
  labelName: "Name",
  labelLastName: "Vorname",
  labelTelephone: "Telefon",
  labelMessage: "Nachricht",

  sendMsg: "Senden",

  contactHeader: "Kontakt",
  contactSubHeader: "...",

  aboutSquibAppText: "...",
  aboutSquibAppDesc: "...",
  contactAddressHeader: "...",

  createVotingBtn: "Voting erstellen",

  stepsTitle: "...",
  step1: "...",
  step2: "...",
  step3: "...",
  callToActionTitle: "...",

  squibProDesc: "...",

  errorTitle: "Sorry, dieses Voting ist bereits abgeschlossen.",
  errorHintText: "Erstelle ein neues Voting hier!",

  integration: "Integration",
  product: "Produkt",
  discover: "Entdecken",
  resources: "Ressourcen",

  uberSquib: "Über Squib",
  blog: "Blog",
  contactUs: "Kontakt",
  jobs: "Jobs",
  media: "Medien",
  imprint: "Impressum",
  footerPolicy:
    ". Squib ist eine urheberrechtlich geschützte Marke von Squib Ltd. Alle Rechte vorbehalten. Alle anderen Marken sind das Eigentum der jeweiligen Inhaber.",

  linkImprintPrivacy: "Impressum & Datenschutzerklärung",

  mobileMenuOne: "Squib entdecken",
  mobileMenuTwo: "Anwendungsbeispiele",
  mobileMenuThree: "Kontakt",

  waitingListBtnText: "Auf die Warteliste setzen",

  btnCreateVoting: "Voting erstellen",
  btnStartVoting: "Voting Starten",
  btnNewCreateVoting: "Neues Voting erstellen",
  btnLearnMore: "Mehr erfahren",
  btnSendMessage: "Nachricht senden",

  headerContactInfo: "Verantwortlich für den Inhalt der Webseite:",

  imprintPrivacyHeaderQuestion: "Möchtest du Squib nutzen oder hast du eine Frage?",
  imprintPrivacyCallToActionBtn: "Jetzt kontaktieren!",

  imprintPrivacyContentTitle1: "Impressum & Datenschutz",
  imprintPrivacyAddress: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSchweiz",
  imprintPrivacyResponsive: "Marco Eichenberger",

  imprintPrivacyContentTitle: "Datenschutzerklärung",
  imprintPrivacyContent1One: "Der Schutz Ihrer Daten ist uns ein zentrales Anliegen und hat höchste Priorität.",
  imprintPrivacyContent1Two:
    "Diese Datenschutzerklärung legt dar, wie wir (nachfolgend auch «Squib» genannt) mit Ihren personenbezogenen Daten verfahren, die wir erfassen oder erhalten, wenn Sie squib.app (Website) oder weitere Domains, Dienste oder Inhalte besuchen oder nutzen (nachfolgend «unsere Dienste» genannt). Dazu gehören auch unsere mobilen Anwendungen für iOS und Android. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Auf unsere Datenverarbeitung kommt das Schweizer Datenschutzgesetz zur Anwendung. Unter Umständen ist auch die Europäische Datenschutz­grundverordnung anwendbar, die wir im Nachfolgenden mit DSGVO abkürzen.",
  imprintPrivacyContent1Three: "Letzte Aktualisierung: Februar 2024",

  imprintPrivacyContentTitle2: "Geltungsbereich dieser Datenschutzerklärung",
  imprintPrivacyContent2One:
    "Diese Datenschutzerklärung bezieht sich auf die Verarbeitung von Daten, die generiert werden, wenn Sie unsere Dienste nutzen oder darauf zugreifen.",

  imprintPrivacyContent2Two:
    "Diese Datenschutzerklärung findet keine Anwendung für die Praxis von Drittparteien, die wir nicht besitzen, kontrollieren oder verwalten. Dies sind Websites, Dienste, Anwendungen und Firmen von Drittparteien. Wir sind zwar bestrebt, nur mit Diensten von Drittparteien zu arbeiten, welche unseren Respekt für den Schutz Ihrer Daten teilen, tragen aber keine Verantwortung für die Richtlinien dieser Drittparteien bezüglich Inhalten und Datenschutz. Wir möchten Sie dazu motivieren, die Datenschutzrichtlinien sämtlicher Dienste von Drittparteien zur Kenntnis zu nehmen, auf die Sie zugreifen.",
  imprintPrivacyContent2Three:
    "Des Weiteren regelt diese Datenschutzerklärung nicht, was unsere Benutzer auf ihren Squib-Profilen tun und wir tragen keine Verantwortung für die Praxis der Erfassung und Nutzung von Daten durch einzelne Nutzer.",

  imprintPrivacyContentTitle3: "Erfassung und Verwendung von Daten",
  imprintPrivacyContent3One:
    "Im Zuge der Bereitstellung der Dienste erfassen und erhalten wir Daten unterschiedlicher Kategorien. Bestimmte Datenkategorien, die Squib erfasst, sind für die Nutzung unserer Dienste notwendig. So müssen wir Ihre Daten in bestimmten Weisen verarbeiten, um Ihnen unsere Dienste in Übereinstimmung mit unseren Allgemeinen Geschäftsbedingungen zur Verfügung zu stellen. Unsere Allgemeinen Geschäftsbedingungen zeigen auf, dass die Verarbeitung von Daten für die Zwecke personalisierter Inhalte und Werbeinhalte ein Teil der Dienste ist, die wir bereitstellen.",
  imprintPrivacyContent3Two:
    "Squib kann in Einzelfällen andere rechtliche Grundlagen heranziehen um Ihre Daten zu verarbeiten, beispielsweise Schutz unserer Nutzer (beispielsweise bei drohender Gefahr), Erfordernis im öffentlichen Interesse oder zur Einhaltung rechtlicher Obliegenheiten und gerichtlicher Anordnungen oder zur Vorbringung gegen rechtliche Ansprüche.",

  imprintPrivacyContentTitle4: "Accountdaten",
  imprintPrivacyContent4:
    "Wenn Sie in unseren Diensten einen Account einrichten, können Sie Daten wie Benutzername, Alter, Geschlecht, Standort hinterlegen, um die Dienste optimal zu nutzen. Diese Daten können wir anonymisiert verwenden, um die Dienste zu erweitern oder zu verbessern, beispielsweise durch Personalisierung. Wir können z.B. Ihr Alter heranziehen, um zu bestätigen, dass es Ihnen gesetzlich gestattet ist, die Dienste zu nutzen und um unsere Inhalte an Ihr Alter anzupassen. Diese Einstellungen können Sie in den Einstellungen in unseren Diensten jederzeit anpassen.",

  imprintPrivacyContentTitle5: "Daten über Ihre Accounts auf Diensten von Drittanbietern",
  imprintPrivacyContent5:
    "Sie können Ihren Account mit bestimmten Diensten von Drittparteien verknüpfen (z.B. bei der Anmeldung). Um dies zu tun, kann es erforderlich sein, sich bei diesem Dienst anzumelden. Nach diesem Anmeldevorgang erhalten wir ein Token, mit dem wir Zugriff auf Ihren Account in diesem Dienst erhalten, damit wir beispielsweise Ihre Inhalte in diesem Dienst veröffentlichen können, sofern Sie dies wünschen. Weder erhalten noch speichern wir Ihre Passwörter für Accounts bei Diensten von Drittanbietern.",

  imprintPrivacyContentTitle6: "Inhalte von Benutzern",
  imprintPrivacyContent6:
    "Sämtliche Interaktionen im Rahmen der Votings sind grundsätzlich öffentlich, sofern die Votings öffentlich geteilt werden. Wird nicht anders darauf hingewiesen, sollten Sie stets annehmen, dass Ihre Interaktionen nicht vertraulich behandelt werden. Sie sollten mögliche Veröffentlichung von Interaktionen auf Squib nicht fürchten, sich aber bewusst sein, dass es potentiell schwierig sein könnte, sie wieder komplett aus dem Internet zu entfernen.",

  imprintPrivacyContentTitle7: "Rechte der Benutzer bezüglich ihrer Daten",
  imprintPrivacyContent7One:
    "Die Urheber- und alle anderen Rechte von Ihnen erstellten Inhalte, Videos, Fotos oder anderen Dateien gehören ausschliesslich Ihnen oder den speziell genannten Rechteinhabern. Für die Reproduktion von sämtlichen Dateien, ist die schriftliche Zustimmung des Urheberrechtsträgers bzw. der Urheberrechtsträgerin im Voraus einzuholen. Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich strafbar und allenfalls schadenersatzpflichtig machen.",
  imprintPrivacyContent7Two:
    "Sie haben überdies jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.",

  imprintPrivacyContentTitle8: "Systemeigene Aktionen",
  imprintPrivacyContent8:
    "Die Dienste ermöglichen bestimmte Aktionen, die für unsere Produkte wesentlich sind, beispielsweise 'Votes'. Wir können diese Daten nutzen, um die Dienste zu verbessern, neue Dienste zu entwickeln und speziell Ihre Erfahrung auf Squib zu personalisieren. Zur Personalisierung mittels solcher Daten kann gehören, Ihnen neue Beiträge zu zeigen, die Bezug zu Beiträgen besitzen, mit denen Sie interagierten, bessere Nutzererlebnisse für Sie bereitzustellen und Ihnen Werbeinhalte zu zeigen, die höhere Relevanz für Ihre Interessen besitzt.",

  imprintPrivacyContentTitle9: "Daten bezüglich der Nutzung der Dienste",
  imprintPrivacyContent9:
    "Wir erfassen Daten darüber, wie Benutzer unsere Dienste nutzen. Diese Art von Daten kann jedes Mal, wenn Sie mit den Diensten interagieren, in unseren Protokolldateien erfasst werden. Wir verwenden interne Tools sowie Anwendungen und Dienste von Drittparteien (darunter Google Analytics), um solche Daten zu erfassen und zu analysieren. Manche dieser Daten können auch mit der Internet-Protokolladresse ('IP-Adresse') verknüpft sein, die für den Zugriff auf die Dienste verwendet wird; manche sind mit Ihrem Account verknüpft, manche werden nur in zusammengefasster Form erfasst und genutzt (als statistische Kennzahl, die Sie oder Ihren Account nicht identifizieren). Wir können diese Daten darüber, wie Sie und andere mit den Diensten interagieren, für eine Reihe von Dingen nutzen, die generell mit der Erweiterung, Verbesserung und dem Schutz der Dienste und der Entwicklung neuer Dienste zusammenhängen, darunter: Bereitstellung personalisierter Inhalte für Benutzer, Bereitstellung gezielter Werbeinhalte für Benutzer, Verbesserung unserer Suchergebnisse, Feststellung beliebter Inhalte, Abwehr von Spam, Malware und Identitätsdiebstahl und generell Sicherheit unserer Nutzer und Community und für Zwecke von Recht und Sicherheit.",

  imprintPrivacyContentTitle10: "Daten mit Bezug zu Ihrem Browser",
  imprintPrivacyContent10:
    "Wir erhalten automatisch Daten von Ihrem Browser, wenn Sie mit den Diensten interagieren, darunter Typ und Version Ihres Browsers, Art des von Ihnen genutzten Geräts, das Betriebssystem und dessen Version, Datum und Zeit jeder von Ihnen an die Dienste gerichteten Abfrage sowie Daten von Cookies, die wir in Ihrem Browser gesetzt haben. Wir nutzen Browserdaten für die Bereitstellung, Sicherheit, Verbesserung und Erweiterung der Dienste.",

  imprintPrivacyContentTitle11: "SSL- bzw. TLS-Verschlüsselung",
  imprintPrivacyContent11:
    "Unsere Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Votings oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.",

  imprintPrivacyContentTitle12: "Standortdaten",
  imprintPrivacyContent12:
    "In manchen Fällen erfassen wir Daten über Ihren Standort. Beispielsweise kann Ihre IP-Adresse in einen ungefähren geografischen Standort umgesetzt werden. Wir können zudem um Daten über Ihren Standort bitten, beispielsweise die Erlaubnis zur Nutzung geografischer Daten Ihres Mobilgerätes, um Inhalte in Ihrer Umgebung zu finden. Wir können Standortdaten dazu nutzen, die Dienste zu verbessern und für Sie zu personalisieren, wozu sowohl die Bereitstellung relevanter Inhalte gehört, als auch das Anzeigen von relevanten Werbeanzeigen.",

  imprintPrivacyContentTitle13: "Daten mit Bezug zu Ihrem Mobilgerät",
  imprintPrivacyContent13:
    "Wir können Daten mit Bezug zu Ihrem Mobilgerät erfassen und speichern. Dies geschieht für die Zwecke, die im oberen Abschnitt 'Daten bezüglich der Nutzung der Dienste' dargelegt wurden.",

  imprintPrivacyContentTitle14: "Daten, die mittels Cookies und Web-Tags erfasst werden",
  imprintPrivacyContent14:
    "Cookies sind Textdateien, die an Ihren Browser gesendet und dort gespeichert werden, wenn Sie auf eine Website zugreifen. Ihr Browser speichert diese Cookies in Zusammenhang mit den besuchten Websites und Sie können (abhängig vom genutzten Browser) Cookies in den Einstellungen des Browsers ansehen. Ein Web-Tag ist Code oder ein Pixel, der in eine Webseite eingebettet wird und Squib einer Drittpartei ermöglicht, festzustellen, dass Sie die Seite aufgerufen haben. Wir nutzen Cookies und Web-Tags, damit unsere Server Ihren Browser erkennen und übermitteln können, wie und wann Sie die Dienste nutzen, wie oben im Abschnitt „Daten bezüglich der Nutzung der Dienste“ dargelegt. Unsere Cookies enthalten in sich keine Daten, welche Sie direkt identifizieren und wir kombinieren die allgemeinen Daten, welche von Cookies erfasst werden, nicht mit weiteren Daten, welche Sie identifizieren. Die Analyse Ihres “Online-Verhaltens” erfolgt also anonym, dieses kann nicht zu Ihnen zurückverfolgt werden. Sie können (je nach Browser) auf verschiedene Weise entscheiden, ob und wie Sie neue Cookies akzeptieren. Wenn Sie Cookies deaktivieren, können Sie den einige unserer Dienste nicht nutzen. Daher raten wir davon ab, Cookies zu deaktivieren, wenn Sie unsere Dienste nutzen. Einige der von uns genutzten Dienste (darunter Dienste von Drittparteien), beispielsweise http://www.google.com/policies/privacy/, verwenden Web-Tags und können zudem eigene Cookies in Ihrem Browser setzen.",

  imprintPrivacyContentTitle15: "Weitergabe Ihrer Daten",
  imprintPrivacyContent15:
    "Wir geben Daten, die wir von Ihnen erhalten, grundsätzlich nicht weiter. Ausnahmen sind, wenn; a) wir Ihre Erlaubnis zur Weitergabe dieser Daten besitzen, um beispielsweise Dienste bereitzustellen, die Sie wünschen (darunter Ihre Verbindung mit Diensten von Drittparteien); b) bei berechtigten Anfragen und auf Anordnung von Behörden, siehe nachfolgend 'Offenlegung von Daten zu unserem Schutz und zum Schutz Dritter' oder c) die Daten zusammengefasste bzw. anonymisierte Daten oder sonstige Daten sind, welche Sie nicht identifizieren.",

  imprintPrivacyContentTitle16: "Offenlegung von Daten zu unserem Schutz und zum Schutz Dritter",
  imprintPrivacyContent16:
    "Wir glauben an eine rechtskonforme Nutzung unserer Dienste durch unsere Community. Ungeachtet dessen behalten wir uns das Recht vor, im alleinigen Ermessen auf Daten zuzugreifen, diese zu speichern und offenzulegen, wenn wir zu dem Schluss kommen, dass dies notwendig ist, um: (i) Gesetzen und Bestimmungen, Rechtsverfahren, Anfragen und Anordnungen von Behörden zu entsprechen, (ii) diese Datenschutzerklärung, unsere Allgemeinen Geschäftsbedingungen sowie sämtliche anderen Verträge mit Ihnen durchzusetzen , darunter Untersuchung potentieller Vertragsverletzungen, (iii) Probleme in Bezug auf Betrug, Sicherheit, Vertraulichkeit und Schutz und Technik zu entdecken, verhüten, untersuchen oder sonstig in Angriff zu nehmen, (iv) Reaktion auf Supportanfragen von Benutzern und (v) Schutz der Rechte, des Eigentums, der Gesundheit und Sicherheit von uns, unseren Benutzern, Drittparteien und der Öffentlichkeit, darunter in Situationen mit der Möglichkeit von Gewalt, Suizid oder Selbstverletzung.",

  imprintPrivacyContentTitle17: "Sicherheit und Aufbewahrung Ihrer Daten",
  imprintPrivacyContent17One:
    "Unsere Systeme und die Daten unserer Benutzer zu schützen, hat für Squib höchste Priorität und ist entscheidend, um Sicherheit zu gewährleisten und das Vertrauen unserer Nutzer zu wahren.",
  imprintPrivacyContent17Two:
    "Squib bewahrt Ihre Daten nur so lange auf, wie es für die Zwecke notwendig ist, die in dieser Datenschutzerklärung dargelegt werden, oder für die Zeit, die Ihr Account bei Squib aktiv ist oder es notwendig ist, um die Dienste für Sie bereitzustellen. Wenn Sie nicht mehr wünschen, dass Squib Ihre Daten verwendet, um die Dienste für Sie bereitzustellen, können Sie Ihren Account schliessen und Squib wird die gespeicherten Daten nach einer Reaktivierungsfrist von 30 Tagen löschen, ausser wenn wir Ihre Daten zur Einhaltung rechtlicher Obliegenheiten, zur Beilegung von Streitfällen, zur Durchsetzung unserer Verträge und wie anderweitig gesetzlich gestattet aufbewahren und nutzen muss.",

  imprintPrivacyContentTitle18: "Berechtigte Interessen",
  imprintPrivacyContent18One:
    "Squib kann Ihre Daten anonym nutzen, um seine Dienste und andere (Werbe)-Inhalte, die wir Ihnen anbieten, bereitzustellen, zu verbessern und zu personalisieren. Wir tun dies in dem Masse, in dem es notwendig ist, unsere berechtigten Interessen zu verfolgen, sowie Ihre berechtigten Interessen des Erhaltes personalisierter Inhalte und Dienste zu wahren. Die Nutzung Ihrer Daten zu solchen Zwecken ist zudem notwendig, um Wege zu entwickeln, unsere Dienste zu optimieren, damit sie für Sie und die anderen Benutzer attraktiver und interessanter werden.",
  imprintPrivacyContent18Two:
    "Wir können Ihre Daten zudem für Zwecke des Schutzes und der Sicherheit verwenden. Dazu gehört die Weitergabe Ihrer Daten für solche Zwecke. Wir tun dies, wenn es für die Erfüllung unserer und Ihrer berechtigten Interessen der Sicherheit unserer Dienste notwendig ist, darunter verbesserten Schutz der Benutzer gegen Belästigung, Verletzung geistigen Eigentums, Spam und Kriminalität sowie Sicherheitsrisiken aller Art.",

  imprintPrivacyContentTitle19: "Löschung Ihres Accounts und deren Folgen",
  imprintPrivacyContent19:
    "Wenn Sie Ihren Account löschen möchten, kontaktieren Sie uns per E-Mail an delete@squib.app von der E-Mail-Adresse, mit der Sie bei Squib registriert sind. Sie erhalten danach eine Nachricht unsererseits, womit Sie die Löschung bestätigen müssen. Ihre Daten werden danach vollständig gelöscht. Da in unseren Diensten Austausch herrscht, könnten öffentliche Aktivitäten in Ihrem Account, die vor der Löschung stattfanden, auf unseren Servern gespeichert werden und öffentlich zugänglich bleiben.",

  imprintPrivacyContentTitle20: "Widerruf der Einwilligung",
  imprintPrivacyContent20:
    "Sie haben das Recht, Ihre Einwilligung zu unserer Verarbeitung Ihrer Daten und Ihre Nutzung unserer Dienste jederzeit zu widerrufen. Wenn Sie Ihre Einwilligung zur Nutzung oder Weitergabe Ihrer Daten zu Zwecken, die in dieser Datenschutzerklärung dargelegt sind, widerrufen, haben Sie ggf. keinen Zugriff mehr auf Teile der Dienste oder die gesamten Dienste und wir sind ggf. nicht in der Lage, Ihnen die Dienste oder Teile der Dienste im Rahmen dieser Datenschutzerklärung und unserer AGB zur Verfügung zu stellen.",

  imprintPrivacyContentTitle21: "Hinweis zur verantwortlichen Stelle",
  imprintPrivacyContent21:
    "Die verantwortliche Stelle im Sinne des Datenschutzrechts ist der Betreiber dieser Website. Dessen Kontaktdaten sind dem Impressum dieser Website zu entnehmen. Bitte setzen Sie sich mit der verantwortlichen Stelle in Verbindung, wenn Sie Fragen oder Korrektur-/Löschungswünsche in Bezug auf Ihre persönlichen Daten haben.",

  imprintPrivacyContentTitle22: "Dienste von Drittanbietern",
  imprintPrivacyContent22:
    "Sie können über unsere Dienste auf die Dienste Dritter zugreifen, z.B. durch das Anklicken von Links. Sie haben auch die Möglichkeit, Inhalte unserer Dienste mit den Diensten Dritter zu teilen (z.B. Instagram oder Facebook). Die vorliegenden Datenschutzbestimmungen gelten ausschliesslich für Daten auf unseren Diensten. Sie sind dafür verantwortlich, die Datenschutzrichtlinien dieser Drittanbieterdienste zu verstehen. Wir distanzieren uns hiermit ausdrücklich von allen Inhalten Dritter, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.",

  imprintPrivacyContentTitle23: "Google Analytics",
  imprintPrivacyContent23One:
    "Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
  imprintPrivacyContent23Two:
    "Google Analytics verwendet so genannte 'Cookies'. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",
  imprintPrivacyContent23Three:
    "Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Angebot als auch seine Werbung zu optimieren.",

  imprintPrivacyContentTitle24: "IP Anonymisierung",
  imprintPrivacyContent24:
    "Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",

  imprintPrivacyContentTitle25: "Browser-Plugin",
  imprintPrivacyContent25:
    "Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de.",

  imprintPrivacyContentTitle26: "Demographische Merkmale bei Google Analytics",
  imprintPrivacyContent26:
    "Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren.",

  imprintPrivacyContentTitle27: "Google Adsense",
  imprintPrivacyContent27One:
    "Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. („Google“). Google AdSense verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website ermöglicht. Google AdSense verwendet auch so genannte Web Beacons (unsichtbare Grafiken). Durch diese Web Beacons können Informationen wie der Besucherverkehr auf diesen Seiten ausgewertet werden.",
  imprintPrivacyContent27Two:
    "Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Website (einschliesslich Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von Google weiter gegeben werden. Google wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten zusammenführen.",

  imprintPrivacyContentTitle28: "Google Firebase",
  imprintPrivacyContent28:
    "Zur Speicherung von personenbezogenen Daten sowie durch Nutzer selbst generierte Daten verwenden wir Teile des Services von Google Firebase (Google LLC,1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Dieser Dienst verarbeitet u.a. auch personenbezogene Daten. Meist handelt es sich hierbei um „Instance IDs“, die mit einem Zeitstempel versehen sind. Diese IDs werde einem bestimmten Nutzer zugeordnet und erlauben die Verknüpfung von unterschiedlichen Geschehnissen oder Abläufen. Diese Daten geben für uns keinen Rückschluss auf den konkreten Nutzer. Eine Personalisierung erfolgt durch uns nicht. Wir verarbeiten diese zusammengefassten Daten auch zur Analyse und Optimierung des Nutzungsverhaltens, wie beispielsweise durch die Auswertung von Absturzberichten.",

  imprintPrivacyContentTitle29: "MailChimp",
  imprintPrivacyContent29:
    "Der Versand unserer E-Mailings erfolgt mittels «MailChimp». MailChimp ist ein Dienst des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE, #5000, Atlanta, GA 30308, USA. Wir nutzen diesen Dienst zur Analyse und Auswertung unserer Mailings. Von Mailing-Empfängern zur Verfügung gestellte Daten sowie Klickdaten aus den E-Mails werden auf den Servern von MailChimp in den USA gespeichert. Die Datenschutzbestimmungen von MailChimp finden Sie unter https://mailchimp.com/legal/privacy/.",

  imprintPrivacyContentTitle30: "Ihre Rechte als betroffene Person in der Europäischen Union",
  imprintPrivacyContent30One:
    "Werden Ihre personenbezogenen Daten verarbeitet und ist die DSGVO anwendbar, stehen Ihnen uns gegenüber folgende Rechte zu:",
  contentHeader30One: "Auskunftsrecht",
  contentHeader30Two: "Recht auf Berichtigung",
  contentHeader30Three: "Recht auf Einschränkung der Verarbeitung",
  contentHeader30Four: "Löschungspflicht",
  contentHeader30Five: "Recht auf Unterrichtung",
  contentHeader30Six: "Widerspruchsrecht",
  content30OneParagraph:
    "Sie können von uns eine Bestätigung darüber verlangen, ob wir personenbezogene Daten, die Sie betreffen, verarbeiten. Wenn ja, können Sie von uns Auskunft über diese personenbezogenen Daten sowie über folgende Informationen verlangen:",
  content1PointA: "die Zwecke, zu denen wir die Verarbeitung vornehmen;",
  content1PointB: "die Kategorien von personenbezogenen Daten, die wir verarbeiten;",
  content1PointC:
    "die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;",
  content1PointD:
    "die geplante Dauer der Speicherung der personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;",
  content1PointE:
    "das Bestehen eines Rechts auf Berichtigung oder Löschung der personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen diese Verarbeitung;",
  content1PointF: "das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;",
  content1PointG:
    "alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;",
  content1PointH:
    "das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling gemäss Artikel 22(1) und (4) DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.",

  imprintPrivacyContent30Two:
    "Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob personenbezogene Daten, die Sie betreffen, in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gemäss Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.",

  content30TwoParagraph:
    "Sie haben ein Recht auf Berichtigung und/oder Vervollständigung, sofern die verarbeiteten personenbezogenen Daten, unrichtig oder unvollständig sind. Wir haben die Berichtigung unverzüglich vorzunehmen.",

  content30ThreeParagraph:
    "Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der personenbezogenen Daten verlangen:",

  content3FirstPoint:
    "wenn Sie die Richtigkeit der personenbezogenen Daten für eine Dauer bestreiten, die es uns ermöglicht, deren Richtigkeit zu überprüfen;",
  content3SecondPoint:
    "wenn die Verarbeitung unrechtmässig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;",
  content3ThirdPoint:
    "wenn wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen; oder",
  content3FourthPoint:
    "wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben und noch nicht feststeht, ob unsere berechtigten Gründe gegenüber Ihren Gründen überwiegen.",

  imprintPrivacyContent30Three:
    "Wurde die Verarbeitung der personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder einer ihrer Mitgliedstaaten verarbeitet werden.",

  content30FourParagraph:
    "Sie können von uns verlangen, dass wir diepersonenbezogenen Daten unverzüglich löschen, und wir sind verpflichtet, diese Daten unverzüglich zu löschen, sofern einer derfolgenden Gründe zutrifft:",

  content4PointA:
    " Die personenbezogenen Daten sind für die Zwecke, für die wir sie erhoben oder auf sonstige Weise verarbeitet haben, nicht mehr notwendig.",
  content4PointB:
    " Sie legen Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor.",
  content4PointC: "Die personenbezogenen Daten wurden unrechtmässig verarbeitet.",
  content4PointD:
    "Das Recht auf Löschung besteht nicht, soweit die Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.",

  imprintPrivacyContent30Four:
    "Wurde die Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen aufgehoben, werden wir Sie darüber informieren, wenn wir die Einschränkung aufheben.",

  content30FiveParagraph:
    "Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung geltend gemacht, sind wir verpflichtet, allen Empfängern, denen die personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismässigen Aufwand verbunden.",

  content30SixParagraph:
    "Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der personenbezogenen Daten, die wir aufgrund der Rechtsgrundlage eines berechtigten Interesses verarbeiten, Widerspruch einzulegen. Wir verarbeiten die Sie betreffenden personenbezogenen Daten in diesem Fall nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",

  imprintPrivacyContentTitle31: "Änderungen an dieser Datenschutzerklärung",
  imprintPrivacyContent31:
    "Diese Datenschutzerklärung kann jederzeit geändert werden. Sie sollten dies regelmässig prüfen. Wenn wir wesentliche Änderungen vornehmen, werden wir Ihnen das mitteilen. Dies geschieht entweder durch gut sichtbare Veröffentlichung einer Benachrichtigung über solche Änderungen vor deren Inkrafttreten oder durch direkte Nachricht an Sie. Bitte ziehen Sie das Datum der „Letzten Aktualisierung“ heran, um zu sehen, wann die Datenschutzerklärung zum letzten Mal aktualisiert wurde.",

  metadataContentHome:
    "Squib - die einfache und zuverlässige Art, Feedback zu erhalten. Erstelle attraktive Umfragen, mit denen du garantiert und schnell Feedback erhältst. Einfach clever. Professionell. Mit deinem Logo. Jetzt kostenlos testen.",
  metadataContentExplore:
    "Weshalb Squib? Mit Squib holst du erfolgreicher Feedback ein als bisher. Dank einzigartiger Nutzerfreundlichkeit, welche die Zielgruppen zur Teilnahme motiviert.",
  metadataContentUseCases:
    "Squib - Use Cases Alles geht: Mitarbeitende miteinbeziehen, Kunden befragen, Schüler um Feedback bitten, Online-Community involvieren etc.",
  metadataContentContact:
    "Squib - Jetzt Kontakt aufnehmen. Kontaktformular für alle deine Fragen, für Feedback und für Stakeholder Management.",
  metadataContentImprintPrivacy:
    "Squib - Impressum und Datenschutz Wir nehmen den Schutz deiner persönlichen Daten sehr ernst! Hinweise zum Datenschutz, zu individuellen Rechten sowie Dienste von Drittanbietern.",
};
