export const english = {
  home: "Home",
  createVote: "Create a Voting",
  explore: "Discover Squib",
  useCases: "Use cases",
  pricingFeatures: "Pricing & Features",
  successStories: "Success Stories",
  comparison: "Squib in comparison",
  security: "Security",
  integrations: "Integrations",
  releases: "Releases",
  imprintPrivacy: "Imprint & Privacy",
  faqHelp: "FAQ & Help",
  voting: "Voting",
  votingDeleted: "Voting deleted",
  votingClosed: "Voting closed",
  about: "About",
  contact: "Contact",
  balance: "Balance",
  error: "Error",

  comparisonUrl: "...",
  comparisonFooter: "...",

  voteForA: "...",
  voteForB: "...",

  voteForThis: "...",
  voteForThat: "...",

  homeHeaderTitle: "Simplify your surveys.",
  homeHeaderDesc: "The easiest way to create engaging surveys that all your target audiences will love.",

  errorWaitingListMessage: "Please enter a valid email address.",

  startUpNightsParagraph:
    "«With Squib, we were able to feel the pulse of the participants directly during the event. Thanks to the smart survey design, we achieved a great response rate and particularly authentic feedback.»",
  benedictParagraph:
    "«As a leading school, it's crucial for us to address the needs of our learners. By using Squib, we've been able to gather particularly authentic and immediate feedback. Also, Squib provides the results clearly, allowing us to draw conclusions very efficiently.»",

  useCasesTitle: "Use cases",
  useCasesTitleMobile: "Use cases",
  useCasesSubTitle:
    "Organisations can only achieve long-term success by consistently attending to the needs of their stakeholders. Below you find a selection of business examples from various organisations that use Squib successfully.",

  cardOneUseCasesTwoText: "...",
  cardThreeUseCasesText: "...",

  getInTouchUseCasesHeader: "Do you want to use Squib or do you have a question?",
  getInTouchUseCasesBtn: "Contact now",

  successStoriesTitle: "...",
  successStoriesSubTitle: "...",

  getInTouchSuccessStoriesHeader: "...",
  getInTouchSuccessStoriesBtn: "...",

  clientNeedsSubtitle:
    "«With Squib, we were able to immediately gauge the participants' sentiment during the event. Thanks to the smart survey design, we achieved a great response rate and particularly authentic feedback.»",
  clientNeedsParagraph: "www.startup-nights.com",
  clientNeedsAuthor: "Alyssia Kugler",
  clientNeedsAuthorTitle: "Director",

  pricingFeaturesTitle: "...",
  pricingFeaturesSubTitle: "...",
  appVersionHeader1: "...",
  appVersionHeader2: "...",
  appVersionHeader3: "...",
  appVersionsDesc: "...",
  appVersionBtn1: "...",
  appVersionBtn2: "...",
  freeRegistration: "...",
  pricePro: "...",
  priceProPlus: "...",

  components: "Components",

  whySquib: "Why Squib",
  feedback: "Feedback is fun",
  exploreHeaderTitle: "Why Squib?",
  exploreHeaderDesc:
    "With Squib, you get more and most authentic online feedback than with any other tools. Its extremely user-friendly and straightforward design encourages participation from your target audiences across all age groups. This has been repeatedly proven through empirical studies.",

  exploreText: "Because good surveys can also be fun.",
  exploreSubText:
    "It's proven that surveys get more participation by being visually appealing and easy to use. Squib's mission is to support organisations cultivate a genuine culture of feedback. In today's world, communication is frequent, yet individual needs often receive insufficient attention.",
  whySquibFeedback: "Which is why Squib inspires:",

  propertyOne: "Keep it simple",
  propertyTwo: "More honest feedback",
  propertyThree: "Clear evaluations",
  propertyFour: "Reliable data",
  propertyOneSub:
    "As attention is a valuable asset in today's world, it is worth focusing on proven and intuitively understandable methods that resonate with people instead of getting lost in a multitude of different types of questions.",
  propertyTwoSub:
    "The intuitive design encourages users to engage with Squib even while on the move or during waiting times. This not only boosts engagement but also results in more authentic responses.",
  propertyThreeSub:
    "The survey results are automatically prepared for you, allowing you to analyse them immediately. Thanks to advanced AI technology, it will soon be possible to categorise, summarise or contextualise qualitative feedback in more comprehensive ways.",
  propertyFourSub:
    "Simply choose whether your survey is publicly accessible or exclusively intended for a specific group of people. This degree of flexibility ensures reliable and secure data collection.",

  advantageTitle: "Advantages you have with Squib:",
  advantageOne: "Simple and intuitive handling, no manual and no training required.",
  advantageTwo: "Customisation with your own logo and styling to meet your individual needs.",
  advantageThree: "Gamification features ensure high engagement and low drop-off rates.",
  advantageFour: "Impressive user experience without the need to download an app.",

  funcQuestion: "Do you want to use Squib or do you have a question?",
  funcCallToActionText: "Contact now",
  anyQuestion: "Any questions or do you need help?",
  getInTouchBtn: "Get in touch now!",

  ctaText: "...",
  subText: "...",

  groupOneTitle1: "Why are classic surveys unpopular?",
  groupOneDesc1:
    "Classic surveys are often tiresome and offer little incentive for participation. Squib revolutionises this process by offering an intuitive and empirically tested method that not only encourages genuine responses but also significantly increases engagement. What makes it truly unique and appealing is that people enjoy taking part!",
  btnGroupOne1: "Learn more",

  versionsTitle: "You want to be among the first to use Squib and benefit from preferential conditions?",
  versionsDescHome: "Sign up now for the waitlist and get exclusive updates on Squib.",
  inputWaitingList: "Your email address",
  btnWaitingList: "Enter here",
  waitingListConfirm: "Thank you!",
  waitingListConfirmSubText: "We'll keep you up to date.",

  btnVersions: "...",

  startupCallToActionHeader: "Do you want to use Squib or do you have a question?",
  btnStartupCallToAction: "Contact us",

  groupOneTitle2: "Less effort, more feedback.",
  groupOneDesc2:
    "Squib enhances the engagement of your target audiences through attractive design. Authentic feedback is achieved with simple yet clever usability. All you need to do now is involve your stakeholders on a regular basis.",
  btnGroupOne2: "See examples",

  btnStartup: "...",

  question: "...",
  questionInp: "...",
  hashtagInp: "...",
  linkTerms: "...",
  linkSub: "...",
  hintText: "...",

  useCasesTitleOne: "Establish needs-based school instruction",
  useCasesDescOne:
    "An ideal learning environment also takes into account the needs of learners. It is important to obtain feedback regularly during the semester. Contemporary and interactive, directly via QR code in the classroom.",

  brandHeader: "This example was realized with",

  useCasesTitleTwo: "Addressing the employees' needs",
  useCasesDescTwo:
    "Listening to employees and involving them is essential for an organisation's success. However, this should be done in an accessible and intuitive manner, either directly at the workplace via a QR codes or remotely, at any time and from any location, via a link.",

  useCasesTitleThree: "Real-time event feedback",
  useCasesDescThree:
    "Engage your event attendees directly and in a clever way to gather valuable feedback on the topics that will help you move forward. This innovative approach to interaction also empowers participants to actively contribute to future developments.",

  useCasesTitleFour: "Success factor co-creation: actively involving customers",
  useCasesDescFour:
    "The approach to actively involve current and future customers makes it possible to tailor products and services towards their needs and preferences: 'market research light' combined with a clever marketing approach leads to innovations that truly resonate with their target audience.",

  useCasesFirstCardHeader: "...",
  useCasesSecondCardHeader: "...",
  useCasesThirdCardHeader: "...",
  useCasesFourthCardHeader: "...",

  votingMainTitle: "...",
  linkOptionTitle: "...",
  dataText: "...",
  dataDesc: "...",

  contactTitle: "Contact",
  contactSubTitle:
    "Are you interested in creating state-of-the-art surveys with Squib? Do you have a specific question or simply want to give us feedback? Contact us by e-mail or LinkedIn. We look forward to it!",
  contactPlace: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSwitzerland",

  checkText1: "I want to use Squib (Pro, Pro+ or Enterprise)",
  checkText2: "I have a question or want to give feedback",
  checkText3: "I would like to work for Squib",
  checkText4: "I am an investor and I am interested in your business case",

  company: "Company name",
  name: "Last name",
  lastname: "First name",
  telephone: "Telephone number",
  message: "Message",
  oblig: "required",

  labelCompany: "Company",
  labelName: "Name",
  labelLastName: "Last Name",
  labelTelephone: "Phone",
  labelMessage: "Message",

  sendMsg: "Send",

  contactHeader: "Contact",
  contactSubHeader: "...",

  aboutSquibAppText: "...",
  aboutSquibAppDesc: "...",
  createVotingBtn: "Create a Voting",

  stepsTitle: "...",
  step1: "...",
  step2: "...",
  step3: "...",
  callToActionTitle: "...",

  contactAddressHeader: "...",

  squibProDesc: "...",

  errorTitle: "Sorry, this Voting is already finished.",
  errorHintText: "Create a new Voting here!",

  integration: "Integration",
  product: "Product",
  discover: "Discover",
  resources: "Resources",

  uberSquib: "About Squib",
  blog: "Blog",
  contactUs: "Contact",
  jobs: "Jobs",
  media: "Media",
  imprint: "Imprint",
  footerPolicy:
    ". Squib is a copyrighted trademark of Squib Ltd. All rights reserved. All other trademarks are the property of their respective owners.",

  linkImprintPrivacy: "Imprint & Privacy",

  mobileMenuOne: "Explore",
  mobileMenuTwo: "Use cases",
  mobileMenuThree: "Contact",

  waitingListBtnText: "Join the waiting list",

  btnCreateVoting: "Create Voting",
  btnStartVoting: "Start Voting",
  btnNewCreateVoting: "Create new Voting",
  btnLearnMore: "Learn more",
  btnSendMessage: "Send Message",

  headerContactInfo: "Responsible for the content of the website:",

  imprintPrivacyHeaderQuestion: "Would you like to use Squib or do you have a question?",
  imprintPrivacyCallToActionBtn: "Contact now!",

  imprintPrivacyContentTitle1: "Imprint & Privacy Policy",
  imprintPrivacyAddress:
    "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSwitzerland",
  imprintPrivacyResponsive: "Marco Eichenberger",

  imprintPrivacyContentTitle: "Privacy Policy",
  imprintPrivacyContent1One: "Protecting your privacy is of the utmost importance to us and is our highest priority.",
  imprintPrivacyContent1Two:
    "This Privacy Policy sets out how we (hereinafter also referred to as 'Squib') treat your personal data that we collect or receive when you visit or use squib.app (Website) or other domains, services or content (hereinafter referred to as 'our Services'). This also includes our mobile applications for iOS and Android. Personal data is any data by which you can be personally identified. The Swiss Data Protection Act applies to our data processing. In some circumstances, the European General Data Protection Regulation is also applicable, which we abbreviate below as DSGVO.",

  imprintPrivacyContent1Three: "Last updated: February 2024",

  imprintPrivacyContentTitle2: "Scope of this privacy policy",
  imprintPrivacyContent2One: "This Privacy Policy applies to the processing of data generated when you use or access our Services.",

  imprintPrivacyContent2Two:
    "This Privacy Policy does not apply to the practices of third parties that we do not own, control or manage. These are third party websites, services, applications and companies. While we strive to work only with third party services that share our respect for the privacy of your information, we have no responsibility for the content and privacy policies of these third parties. We encourage you to be aware of the privacy policies of any third-party services you access.",
  imprintPrivacyContent2Three:
    "Further, this Privacy Policy does not govern what our users do on their Squib profiles, and we are not responsible for the practices of individual users' collection and use of data.",

  imprintPrivacyContentTitle3: "Data collection and use",
  imprintPrivacyContent3One:
    "In the course of providing the Services, we collect and receive data of various categories. Certain categories of data that Squib collects are necessary for you to use our Services. Thus, we need to process your data in certain ways in order to provide you with our Services in accordance with our Terms of Service. Our Terms and Conditions indicate that the processing of data for the purposes of personalized content and promotional content is a part of the Services we provide.",
  imprintPrivacyContent3Two:
    "Squib may rely on other legal bases to process your data in individual cases, for example, protection of our users (for example, in case of imminent danger), requirement in the public interest or to comply with legal obligations and court orders, or to bring forward against legal claims.",

  imprintPrivacyContentTitle4: "AccountData",
  imprintPrivacyContent4:
    "When you create an account in our services, you may store data such as username, age, gender, location to make the best use of the services. We may use this data anonymously to enhance or improve the Services, for example through personalization. For example, we may use your age to confirm that you are legally allowed to use the Services and to customize our content to your age. You can adjust these settings in the settings in our Services at any time.",

  imprintPrivacyContentTitle5: "Data about your accounts on third-party services",
  imprintPrivacyContent5:
    "You may link your account to certain third-party services (e.g., when you sign in). To do so, you may be required to sign in to that service. After this registration process, we will receive a token that will give us access to your account on that service so that we can, for example, publish your content on that service if you wish. We neither receive nor store your passwords for accounts on third-party services.",

  imprintPrivacyContentTitle6: "user content",
  imprintPrivacyContent6:
    "All interactions in the Voting are generally public, provided that the Voting is shared publicly. Unless otherwise indicated, you should always assume that your interactions will not be kept confidential. You should not fear possible publication of interactions on Squib, but be aware that it could be potentially difficult to remove them from the Internet completely.",

  imprintPrivacyContentTitle7: "Users' rights regarding their data",
  imprintPrivacyContent7One:
    "The copyrights and all other rights of content, videos, photos or other files created by you belong exclusively to you or to the specifically mentioned rights holders. For the reproduction of any files, the written consent of the copyright holder must be obtained in advance. Anyone who commits a copyright infringement without the consent of the respective copyright holder may be liable to prosecution and possibly to damages.",
  imprintPrivacyContent7Two:
    "Furthermore, you have the right to receive information free of charge at any time about the origin, recipient and purpose of your stored personal data. You also have the right to request the correction, blocking or deletion of this data. For this purpose, as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.",

  imprintPrivacyContentTitle8: "system custom actions",
  imprintPrivacyContent8:
    "The Services enable certain actions that are essential to our products, such as 'votes'. We may use this data to improve the Services, develop new Services, and specifically personalize your experience on Squib. Personalization using such data may include showing you new posts that are related to posts you have interacted with, providing better user experiences for you, and showing you advertising content that is more relevant to your interests.",

  imprintPrivacyContentTitle9: "Data related to the use of the services",
  imprintPrivacyContent9:
    "We collect data about how users use our Services. This type of data may be collected in our log files each time you interact with the Services. We use internal tools and third-party applications and services (including Google Analytics) to collect and analyze such data. Some of this data may also be linked to the Internet Protocol ('IP') address used to access the Services; some is linked to your account; and some is collected and used only in aggregate form (as a statistical metric that does not identify you or your account). We may use this data about how you and others interact with the Services for a number of things generally related to enhancing, improving and protecting the Services and developing new services, including: Providing personalized content to users, providing targeted advertising content to users, improving our search results, identifying popular content, defending against spam, malware and identity theft, and generally keeping our users and community safe and secure, and for law and security purposes.",

  imprintPrivacyContentTitle10: "Data related to your browser",
  imprintPrivacyContent10:
    "We automatically receive data from your browser when you interact with the Services, including the type and version of your browser, the type of device you are using, the operating system and its version, the date and time of each request you make to the Services, and data from cookies we have set on your browser. We use browser data to provide, secure, improve, and enhance the Services.",

  imprintPrivacyContentTitle11: "SSL or TLS encryption",
  imprintPrivacyContent11:
    "Our website uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as votes or requests that you send to us as site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from 'http://' to 'https://' and by the lock symbol in your browser line. If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.",

  imprintPrivacyContentTitle12: "location data",
  imprintPrivacyContent12:
    "In some cases, we collect data about your location. For example, your IP address may be translated into an approximate geographic location. We may also ask for data about your location, such as permission to use geographic data from your mobile device to find content in your area. We may use location data to improve and personalize the Services for you, which includes both providing relevant content and displaying relevant advertisements.",

  imprintPrivacyContentTitle13: "Data related to your mobile device",
  imprintPrivacyContent13:
    "We may collect and store data related to your mobile device. This is for the purposes set out in the 'Data relating to the use of the Services' section above.",

  imprintPrivacyContentTitle14: "Data collected using cookies and web tags",
  imprintPrivacyContent14:
    "Cookies are text files that are sent to and stored in your browser when you access a website. Your browser stores these cookies in connection with the websites you visit, and you can (depending on the browser you use) view cookies in the browser's settings. A web tag is code or a pixel that is embedded in a web page that allows Squib to tell a third party that you have accessed the page. We use cookies and web tags to allow our servers to recognize your browser and communicate how and when you use the Services, as set forth above in the section entitled 'Data Regarding Use of the Services.' Our cookies do not contain any data within them that directly identifies you, and we do not combine the general data collected by cookies with any other data that identifies you. The analysis of your 'online behavior' is therefore anonymous, this can not be traced back to you. You can decide (depending on your browser) in various ways whether and how to accept new cookies. If you disable cookies, you will not be able to use some of our services. Therefore, we do not recommend disabling cookies when you use our services. Some of the services we use (including third party services), for example http://www.google.com/policies/privacy/, use web tags and may also set their own cookies in your browser.",

  imprintPrivacyContentTitle15: "Sharing your data",
  imprintPrivacyContent15:
    "We generally do not share data we receive from you. Exceptions are when; a) we have your permission to share this data, for example, to provide services you request (including your connection to third party services); b) for legitimate requests and on the orders of authorities, see below 'Disclosure of data for our protection and the protection of third parties' or c) the data is aggregated or anonymized data or other data that does not identify you.",

  imprintPrivacyContentTitle16: "Disclosure of data for our protection and the protection of third parties",
  imprintPrivacyContent16:
    "We believe in legally compliant use of our services by our community. Notwithstanding the foregoing, we reserve the right, in our sole discretion, to access, retain and disclose information when we conclude that it is necessary to: (i) comply with laws and regulations, legal process, requests and orders of governmental authorities; (ii) enforce this Privacy Policy, our Terms and Conditions, and any other agreements we may have with you, including investigation of potential breaches of contract; (iii) address issues related to fraud, security, confidentiality and privacy, and technology, confidentiality and privacy, and technology, (iv) responding to support requests from users, and (v) protecting the rights, property, health and safety of us, our users, third parties and the public, including in situations involving the possibility of violence, suicide or self-harm. ",

  imprintPrivacyContentTitle17: "Security and retention of your data",
  imprintPrivacyContent17One:
    "Protecting our systems and our users' data is a top priority for Squib and is critical to ensuring security and maintaining the trust of our users.",
  imprintPrivacyContent17Two:
    "Squib will retain your information only for as long as is necessary for the purposes set forth in this Privacy Policy or for the time that your account with Squib is active or it is necessary to provide the Services to you. If you no longer want Squib to use your information to provide the Services to you, you may close your account and Squib will delete the stored information after a reactivation period of 30 days, except to the extent we need to retain and use your information to comply with legal obligations, resolve disputes, enforce our contracts, and as otherwise permitted by law.",

  imprintPrivacyContentTitle18: "Legitimate Interests",
  imprintPrivacyContent18One:
    "Squib may use your data anonymously to provide, improve and personalize its services and other (advertising) content we offer to you. We do so to the extent necessary to pursue our legitimate interests, as well as to protect your legitimate interests of receiving personalized content and services. The use of your data for such purposes is also necessary to develop ways to optimize our services to make them more attractive and interesting to you and other users.",
  imprintPrivacyContent18Two:
    "We may also use your data for purposes of protection and security. This includes sharing your data for such purposes. We do so when it is necessary for the fulfillment of our and your legitimate interests of the security of our services, including improved protection of users against harassment, intellectual property infringement, spam and crime, and security risks of all kinds.",

  imprintPrivacyContentTitle19: "Deletion of your account and its consequences",
  imprintPrivacyContent19:
    "If you wish to delete your account, contact us by sending an email to delete@squib.app from the email address with which you are registered at Squib. You will then receive a message from us, with which you must confirm the deletion. Your data will be completely deleted after that. Since there are exchanges in our services, public activity in your account that took place prior to deletion could be stored on our servers and remain publicly available.",

  imprintPrivacyContentTitle20: "Revoke Consent",
  imprintPrivacyContent20:
    "You have the right to withdraw your consent to our processing of your data and your use of our services at any time. If you withdraw your consent to us using or disclosing your information for purposes set forth in this Privacy Policy, you may no longer have access to parts or all of the Services and we may not be able to provide the Services or parts of the Services to you under this Privacy Policy and our TOS.",

  imprintPrivacyContentTitle21: "Notice about the responsible entity",
  imprintPrivacyContent21:
    "The responsible party in the sense of data protection law is the operator of this website. His contact details can be found in the imprint of this website. Please contact the responsible party if you have any questions or correction/deletion requests regarding your personal data.",

  imprintPrivacyContentTitle22: "Third Party Services",
  imprintPrivacyContent22:
    "You may access third party services through our Services, for example, by clicking on links. You also have the option to share content from our services with third-party services (e.g. Instagram or Facebook). This Privacy Policy applies solely to data on our Services. You are responsible for understanding the privacy policies of those third-party services. We hereby expressly distance ourselves from any third-party content that may be relevant under criminal or liability law, or that may be contrary to public morality.",

  imprintPrivacyContentTitle23: "Google Analytics",
  imprintPrivacyContent23One:
    "This website uses functions of the web analytics service Google Analytics. The provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
  imprintPrivacyContent23Two:
    "Google Analytics uses so-called 'cookies'. These are text files that are stored on your computer and enable an analysis of your use of the website. The information generated by the cookie about your use of this website is usually transmitted to and stored by Google on servers in the United States.",
  imprintPrivacyContent23Three:
    "The website operator has a legitimate interest in analyzing user behavior in order to optimize both its offer and its advertising.",

  imprintPrivacyContentTitle24: "IP Anonymization",
  imprintPrivacyContent24:
    "We have activated the IP anonymization function on this website. This means that your IP address is shortened by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area before being transmitted to the USA. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other data from Google.",

  imprintPrivacyContentTitle25: "browser-plugin",
  imprintPrivacyContent25:
    "You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link: https://tools.google.com/dlpage/gaoptout?hl=de.",

  imprintPrivacyContentTitle26: "Demographic characteristics in Google Analytics",
  imprintPrivacyContent26:
    "This website uses the 'demographic characteristics' feature of Google Analytics. This allows reports to be generated that contain statements about the age, gender, and interests of site visitors. This data comes from interest-based advertising from Google as well as visitor data from third-party providers. This data cannot be assigned to a specific person. You can deactivate this function at any time via the ad settings in your Google account.",

  imprintPrivacyContentTitle27: "Google Adsense",
  imprintPrivacyContent27One:
    "This website uses Google AdSense, an advertising embedding service provided by Google Inc ('Google'). Google AdSense uses 'cookies', which are text files placed on your computer, to help the website analyze how users use the site. Google AdSense also uses so-called web beacons (invisible graphics). Through these web beacons, information such as visitor traffic to these pages can be analyzed.",
  imprintPrivacyContent27Two:
    "The information generated by cookies and web beacons about the use of this website (including your IP address) and delivery of advertising formats is transferred to a Google server in the USA and stored there. This information may be passed on by Google to contractual partners of Google. However, Google will not merge your IP address with other data stored by you.",

  imprintPrivacyContentTitle28: "Google Firebase",
  imprintPrivacyContent28:
    "To store personal data as well as data generated by users themselves, we use parts of the service of Google Firebase (Google LLC,1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). This service also processes personal data, among other things. In most cases, this involves 'instance IDs' that are provided with a time stamp. These IDs are assigned to a specific user and allow the linking of different events or processes. This data does not allow us to draw any conclusions about the specific user. We do not carry out any personalization. We also process this aggregated data to analyze and optimize user behavior, such as by evaluating crash reports.",

  imprintPrivacyContentTitle29: "MailChimp",
  imprintPrivacyContent29:
    "Our emailings are sent using 'MailChimp'. MailChimp is a service of the US provider Rocket Science Group, LLC, 675 Ponce De Leon Ave NE, #5000, Atlanta, GA 30308, USA. We use this service to analyze and evaluate our mailings. Data provided by mailing recipients as well as click data from the emails are stored on MailChimp's servers in the USA. The privacy policy of MailChimp can be found at https://mailchimp.com/legal/privacy/.",

  imprintPrivacyContentTitle30: "Your rights as a data subject in the European Union",
  imprintPrivacyContent30One: "If your personal data is processed and the GDPR applies, you have the following rights against us:",
  contentHeader30One: "Right of access",
  contentHeader30Two: "Right of rectification",
  contentHeader30Three: "Right to restriction of processing",
  contentHeader30Four: "Right to erasure",
  contentHeader30Five: "Right to information",
  contentHeader30Six: "Right to object",
  content30OneParagraph:
    "You may request confirmation from us as to whether we are processing personal data relating to you. If so, you may request information from us about that personal data and about the following information:",

  content1PointA: "the purposes for which we carry out the processing;",
  content1PointB: "the categories of personal data we process;",
  content1PointC: "the recipients or categories of recipients to whom the personal data have been or will be disclosed;",
  content1PointD:
    "the planned duration of the storage of the personal data or, if specific information on this is not possible, criteria for determining the storage period;",
  content1PointE:
    "the existence of a right to rectification or erasure of the personal data, a right to restrict processing, or a right to object to such processing;",
  content1PointF: "the existence of a right of appeal to a supervisory authority;",
  content1PointG: "any available information on the origin of the data, if the personal data are not collected from the data subject;",
  content1PointH:
    "the existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.",

  imprintPrivacyContent30Two:
    "You have the right to request information about whether personal data concerning you are transferred to a third country or to an international organization. In this context, you may request to be informed about the appropriate safeguards pursuant to Article 46 of the GDPR in relation to the transfer.",

  content30TwoParagraph:
    "You have a right to rectification and/or completion if the personal data processed, are inaccurate or incomplete. We shall carry out the rectification without delay.",

  content30ThreeParagraph: "You may request the restriction of the processing of personal data under the following conditions:",

  content3FirstPoint: "if you dispute the accuracy of the personal data for a period of time that allows us to verify its accuracy;",
  content3SecondPoint:
    "if the processing is unlawful and you object to the erasure of the personal data and request instead the restriction of the use of the personal data;",
  content3ThirdPoint:
    "if we no longer need the personal data for the purposes of processing, but you need it for the establishment, exercise or defense of legal claims; or",
  content3FourthPoint:
    "if you have objected to the processing and it has not yet been determined whether our legitimate grounds override your grounds.",

  imprintPrivacyContent30Three:
    "Where the processing of personal data has been restricted, such data may be processed, with the exception of storage, only with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person, or for reasons of substantial public interest of the European Union or one of its Member States.",

  content30FourParagraph:
    "You may require us to erase Personal Data without undue delay, and we are obliged to erase such data without undue delay, if one of the following grounds applies:",

  content4PointA: "The personal data are no longer necessary for the purposes for which we collected or otherwise processed them.",
  content4PointB: " You object to the processing and there are no overriding legitimate grounds for processing.",
  content4PointC: "The personal data have been processed unlawfully.",
  content4PointD:
    "The right to erasure does not exist insofar as the processing is necessary for the assertion, exercise or defense of legal claims.",

  imprintPrivacyContent30Four:
    "If the restriction on processing has been lifted under the above conditions, we will inform you when we lift the restriction.",

  content30FiveParagraph:
    "If you have exercised the right to rectification, erasure or restriction of processing, we are obliged to communicate this rectification or erasure of data or restriction of processing to all recipients to whom the personal data have been disclosed, unless this proves impossible or involves a disproportionate effort.",

  content30SixParagraph:
    "You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data that we process on the legal basis of a legitimate interest. We will no longer process the personal data concerning you in this case, unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims.",

  imprintPrivacyContentTitle31: "Changes to this privacy policy",
  imprintPrivacyContent31:
    "This privacy policy is subject to change at any time. You should check it regularly. If we make material changes, we will notify you. We will do so either by prominently posting a notice of such changes before they become effective or by notifying you directly. Please refer to the 'Last Updated' date to see when the Privacy Policy was last updated.",

  metadataContentHome:
    "Squib - the easy and reliable way to get feedback. Create attractive surveys with which you are guaranteed to get feedback quickly. Simply clever. Professional. With your logo. Try it now for free.",
  metadataContentExplore:
    "Why Squib? With Squib you collect feedback more successfully than before. Thanks to unique user-friendliness that motivates target groups to participate.",
  metadataContentUseCases:
    "Squib - Use Cases Anything goes: involve employees, survey customers, ask students for feedback, involve online community, etc.",
  metadataContentContact: "Squib - Contact Now. Contact form for all your questions, feedback and stakeholder management.",
  metadataContentImprintPrivacy:
    "Squib - Imprint and Privacy We take the protection of your personal data very seriously! Privacy notices, individual rights, and third-party services.",
};
