import React from "react";
import "../../../../resources/styles/pages/imprint-privacy.css";
import ImprintPrivacyHeader from "./Imprint-privacy-header/ImprintPrivacyHeader";
import EuropeUnionDataRights from "./Imprint-privacy-content/EuropeUnionDataRights";
import GetInTouchImprintPrivacy from "./getintouch-imprint-privacy/GetInTouchImprintPrivacy";
import Address from "./Address/Address";
import MetaData from "../common/metadata/MetaData";
import { metadataContent } from "../common/metadata/content";
import { useTranslation } from "react-i18next";

const ImprintPrivacy = () => {
  const { t } = useTranslation("translation");
  const mdText = t("imprintPrivacy", { ns: "translation" });

  const headerContactInfo = t("headerContactInfo", { ns: "translation" });

  const title1 = t("imprintPrivacyContentTitle1", { ns: "translation" });

  const contentTitle = t("imprintPrivacyContentTitle", { ns: "translation" });
  const content1One = t("imprintPrivacyContent1One", { ns: "translation" });
  const content1Two = t("imprintPrivacyContent1Two", { ns: "translation" });
  const content1Three = t("imprintPrivacyContent1Three", { ns: "translation" });

  const title2 = t("imprintPrivacyContentTitle2", { ns: "translation" });
  const content2One = t("imprintPrivacyContent2One", { ns: "translation" });
  const content2Two = t("imprintPrivacyContent2Two", { ns: "translation" });
  const content2Three = t("imprintPrivacyContent2Three", { ns: "translation" });

  const title3 = t("imprintPrivacyContentTitle3", { ns: "translation" });
  const content3One = t("imprintPrivacyContent3One", { ns: "translation" });
  const content3Two = t("imprintPrivacyContent3Two", { ns: "translation" });

  const title4 = t("imprintPrivacyContentTitle4", { ns: "translation" });
  const content4 = t("imprintPrivacyContent4", { ns: "translation" });

  const title5 = t("imprintPrivacyContentTitle5", { ns: "translation" });
  const content5 = t("imprintPrivacyContent5", { ns: "translation" });

  const title6 = t("imprintPrivacyContentTitle6", { ns: "translation" });
  const content6 = t("imprintPrivacyContent6", { ns: "translation" });

  const title7 = t("imprintPrivacyContentTitle7", { ns: "translation" });
  const content7One = t("imprintPrivacyContent7One", { ns: "translation" });
  const content7Two = t("imprintPrivacyContent7Two", { ns: "translation" });

  const title8 = t("imprintPrivacyContentTitle8", { ns: "translation" });
  const content8 = t("imprintPrivacyContent8", { ns: "translation" });

  const title9 = t("imprintPrivacyContentTitle9", { ns: "translation" });
  const content9 = t("imprintPrivacyContent9", { ns: "translation" });

  const title10 = t("imprintPrivacyContentTitle10", { ns: "translation" });
  const content10 = t("imprintPrivacyContent10", { ns: "translation" });

  const title11 = t("imprintPrivacyContentTitle11", { ns: "translation" });
  const content11 = t("imprintPrivacyContent11", { ns: "translation" });

  const title12 = t("imprintPrivacyContentTitle12", { ns: "translation" });
  const content12 = t("imprintPrivacyContent12", { ns: "translation" });

  const title13 = t("imprintPrivacyContentTitle13", { ns: "translation" });
  const content13 = t("imprintPrivacyContent13", { ns: "translation" });

  const title14 = t("imprintPrivacyContentTitle14", { ns: "translation" });
  const content14 = t("imprintPrivacyContent14", { ns: "translation" });

  const title15 = t("imprintPrivacyContentTitle15", { ns: "translation" });
  const content15 = t("imprintPrivacyContent15", { ns: "translation" });

  const title16 = t("imprintPrivacyContentTitle16", { ns: "translation" });
  const content16 = t("imprintPrivacyContent16", { ns: "translation" });

  const title17 = t("imprintPrivacyContentTitle17", { ns: "translation" });
  const content17One = t("imprintPrivacyContent17One", { ns: "translation" });
  const content17Two = t("imprintPrivacyContent17Two", { ns: "translation" });

  const title18 = t("imprintPrivacyContentTitle18", { ns: "translation" });
  const content18One = t("imprintPrivacyContent18One", { ns: "translation" });
  const content18Two = t("imprintPrivacyContent18Two", { ns: "translation" });

  const title19 = t("imprintPrivacyContentTitle19", { ns: "translation" });
  const content19 = t("imprintPrivacyContent19", { ns: "translation" });

  const title20 = t("imprintPrivacyContentTitle20", { ns: "translation" });
  const content20 = t("imprintPrivacyContent20", { ns: "translation" });

  const title21 = t("imprintPrivacyContentTitle21", { ns: "translation" });
  const content21 = t("imprintPrivacyContent21", { ns: "translation" });

  const title22 = t("imprintPrivacyContentTitle22", { ns: "translation" });
  const content22 = t("imprintPrivacyContent22", { ns: "translation" });

  const title23 = t("imprintPrivacyContentTitle23", { ns: "translation" });
  const content23One = t("imprintPrivacyContent23One", { ns: "translation" });
  const content23Two = t("imprintPrivacyContent23Two", { ns: "translation" });
  const content23Three = t("imprintPrivacyContent23Three", { ns: "translation" });

  const title24 = t("imprintPrivacyContentTitle24", { ns: "translation" });
  const content24 = t("imprintPrivacyContent24", { ns: "translation" });

  const title25 = t("imprintPrivacyContentTitle25", { ns: "translation" });
  const content25 = t("imprintPrivacyContent25", { ns: "translation" });

  class myContent25 {
    content25 = t("imprintPrivacyContent25", { ns: "translation" });

    constructor() {
      this.content25 = this.convertLink(this.content25);
    }

    convertLink(texto: string): string {
      const enlaceRegex = /https?:\/\/[^\s]+/g;
      return texto.replace(enlaceRegex, match => `<a href="${match}">${match}</a>`);
    }
  }

  const title26 = t("imprintPrivacyContentTitle26", { ns: "translation" });
  const content26 = t("imprintPrivacyContent26", { ns: "translation" });

  const title27 = t("imprintPrivacyContentTitle27", { ns: "translation" });
  const content27One = t("imprintPrivacyContent27One", { ns: "translation" });
  const content27Two = t("imprintPrivacyContent27Two", { ns: "translation" });

  const title28 = t("imprintPrivacyContentTitle28", { ns: "translation" });
  const content28 = t("imprintPrivacyContent28", { ns: "translation" });

  const title29 = t("imprintPrivacyContentTitle29", { ns: "translation" });
  const content29 = t("imprintPrivacyContent29", { ns: "translation" });

  const title30 = t("imprintPrivacyContentTitle30", { ns: "translation" });
  const content30 = t("imprintPrivacyContent30One", { ns: "translation" });
  const contentHeader30One = t("contentHeader30One", { ns: "translation" });
  const contentHeader30Two = t("contentHeader30Two", { ns: "translation" });
  const contentHeader30Three = t("contentHeader30Three", { ns: "translation" });
  const contentHeader30Four = t("contentHeader30Four", { ns: "translation" });
  const contentHeader30Five = t("contentHeader30Five", { ns: "translation" });
  const contentHeader30Six = t("contentHeader30Six", { ns: "translation" });
  const content30OneParagraph = t("content30OneParagraph", { ns: "translation" });
  const content1PointA = t("content1PointA", { ns: "translation" });
  const content1PointB = t("content1PointB", { ns: "translation" });
  const content1PointC = t("content1PointC", { ns: "translation" });
  const content1PointD = t("content1PointD", { ns: "translation" });
  const content1PointE = t("content1PointE", { ns: "translation" });
  const content1PointF = t("content1PointF", { ns: "translation" });
  const content1PointG = t("content1PointG", { ns: "translation" });
  const content1PointH = t("content1PointH", { ns: "translation" });
  const content30Two = t("imprintPrivacyContent30Two", { ns: "translation" });
  const content30TwoParagraph = t("content30TwoParagraph", { ns: "translation" });
  const content30ThreeParagraph = t("content30ThreeParagraph", { ns: "translation" });
  const content3FirstPoint = t("content3FirstPoint", { ns: "translation" });
  const content3SecondPoint = t("content3SecondPoint", { ns: "translation" });
  const content3ThirdPoint = t("content3ThirdPoint", { ns: "translation" });
  const content3FourthPoint = t("content3FourthPoint", { ns: "translation" });
  const content30ThirdParagraph = t("imprintPrivacyContent30Three", { ns: "translation" });
  const content30FourthParagraph = t("content30FourParagraph", { ns: "translation" });
  const content4PointA = t("content4PointA", { ns: "translation" });
  const content4PointB = t("content4PointB", { ns: "translation" });
  const content4PointC = t("content4PointC", { ns: "translation" });
  const content4PointD = t("content4PointD", { ns: "translation" });
  const content30FourthParagraph2 = t("imprintPrivacyContent30Four", { ns: "translation" });
  const content30FiveParagraph = t("content30FiveParagraph", { ns: "translation" });
  const content30SixParagraph = t("content30SixParagraph", { ns: "translation" });

  const title31 = t("imprintPrivacyContentTitle31", { ns: "translation" });
  const content31 = t("imprintPrivacyContent31", { ns: "translation" });

  return (
    <>
      <MetaData
        title={mdText}
        german={metadataContent.metadataGermanContentImprintPrivacy}
        english={metadataContent.metadataEnglishContentImprintPrivacy}
      />
      <div className="imprintPrivacy-comp-container">
        <div className="imprintPrivacy-header-wrapper">
          <ImprintPrivacyHeader />
        </div>
        <div className="address-info-wrapper">
          <p className="header-contact-info">{headerContactInfo}</p>
          <Address
            startUp={"Squib Ltd."}
            place={"c/o Hochschule Luzern - Wirtschaft"}
            desc={"Smart-up"}
            street={"Zentralstrasse 9"}
            zip={"6002 Luzern"}
            country={"Schweiz"}
            dataProtectorOfficer={"Datenschutzbeauftragter"}
            dataProtectorName={"Marco Eichenberger"}
          />
        </div>
        <div className="imprintPrivacy-main-wrapper">
          <div className="privacy-policy-wrapper">
            <div className="first-block">
              <h2 className="text-1">{contentTitle}</h2>
              <p className="text-2">{content1One}</p>
              <p className="text-3">{content1Two}</p>
              <p className="text-4">{content1Three}</p>
            </div>

            <div className="second-block">
              <p className="text-5">{title2}</p>
              <p className="text-6">{content2One}</p>
              <p className="text-7">{content2Two}</p>
              <p className="text-8">{content2Three}</p>
            </div>

            <div className="third-block">
              <p className="text-9">{title3}</p>
              <p className="text-10">{content3One}</p>
              <p className="text-11">{content3Two}</p>
            </div>

            <div className="fourth-block">
              <p className="text-12">{title4}</p>
              <p className="text-13">{content4}</p>
            </div>

            <div className="fifth-block">
              <p className="text-14">{title5}</p>
              <p className="text-15">{content5}</p>
            </div>

            <div className="sixth-block">
              <p className="text-16">{title6}</p>
              <p className="text-17">{content6}</p>
            </div>

            <div className="seventh-block">
              <p className="text-18">{title7}</p>
              <p className="text-19">{content7One}</p>
              <p className="text-20">{content7Two}</p>
            </div>

            <div className="eighth-block">
              <p className="text-21">{title8}</p>
              <p className="text-22">{content8}</p>
            </div>

            <div className="ninth-block">
              <p className="text-23">{title9}</p>
              <p className="text-24">{content9}</p>
            </div>

            <div className="tenth-block">
              <p className="text-25">{title10}</p>
              <p className="text-26">{content10}</p>
            </div>

            <div className="eleventh-block">
              <p className="text-27">{title11}</p>
              <p className="text-28">{content11}</p>
            </div>

            <div className="twelfth-block">
              <p className="text-29">{title12}</p>
              <p className="text-30">{content12}</p>
            </div>

            <div className="thirteenth-block">
              <p className="text-31">{title13}</p>
              <p className="text-32">{content13}</p>
            </div>

            <div className="fourteenth-block">
              <p className="text-33">{title14}</p>
              <p className="text-34">{content14}</p>
            </div>

            <div className="fifteenth-block">
              <p className="text-35">{title15}</p>
              <p className="text-36">{content15}</p>
            </div>

            <div className="sixteenth-block">
              <p className="text-37">{title16}</p>
              <p className="text-38">{content16}</p>
            </div>

            <div className="seventeenth-block">
              <p className="text-39">{title17}</p>
              <p className="text-40">{content17One}</p>
              <p className="text-41">{content17Two}</p>
            </div>

            <div className="eighteenth-block">
              <p className="text-42">{title18}</p>
              <p className="text-43">{content18One}</p>
              <p className="text-44">{content18Two}</p>
            </div>

            <div className="nineteenth-block">
              <p className="text-45">{title19}</p>
              <p className="text-46">{content19}</p>
            </div>

            <div className="twentieth-block">
              <p className="text-47">{title20}</p>
              <p className="text-48">{content20}</p>
            </div>

            <div className="twenty-first-block">
              <p className="text-49">{title21}</p>
              <p className="text-50">{content21}</p>
            </div>

            <div className="twenty-second-block">
              <h3 className="text-51">{title22}</h3>
              <p className="text-52">{content22}</p>
            </div>

            <div className="twenty-third-block">
              <p className="text-53">{title23}</p>
              <p className="text-54">{content23One}</p>
              <p className="text-55">{content23Two}</p>
              <p className="text-56">{content23Three}</p>
            </div>

            <div className="twenty-fourth-block">
              <p className="text-57">{title24}</p>
              <p className="text-58">{content24}</p>
            </div>

            <div className="twenty-fifth-block">
              <p className="text-59">{title25}</p>
              <p className="text-60">{content25}</p>
            </div>

            <div className="twenty-sixth-block">
              <p className="text-61">{title26}</p>
              <p className="text-62">{content26}</p>
            </div>

            <div className="twenty-seventh-block">
              <p className="text-63">{title27}</p>
              <p className="text-64">{content27One}</p>
              <p className="text-65">{content27Two}</p>
            </div>

            <div className="twenty-eighth-block">
              <p className="text-66">{title28}</p>
              <p className="text-67">{content28}</p>
            </div>

            <div className="twenty-ninth-block">
              <p className="text-68">{title29}</p>
              <p className="text-69">{content29}</p>
            </div>
          </div>

          <EuropeUnionDataRights
            headerTitle={title30}
            firstParagraph={content30}
            headerContent1={contentHeader30One}
            headerContent2={contentHeader30Two}
            headerContent3={contentHeader30Three}
            headerContent4={contentHeader30Four}
            headerContent5={contentHeader30Five}
            headerContent6={contentHeader30Six}
            content1={content30OneParagraph}
            point={[
              content1PointA,
              content1PointB,
              content1PointC,
              content1PointD,
              content1PointE,
              content1PointF,
              content1PointG,
              content1PointH,
            ]}
            secondParagraph={content30Two}
            content2={content30TwoParagraph}
            content3={content30ThreeParagraph}
            content3Points={[content3FirstPoint, content3SecondPoint, content3ThirdPoint, content3FourthPoint]}
            thirdParagraph={content30ThirdParagraph}
            content4={content30FourthParagraph}
            point2={[content4PointA, content4PointB, content4PointC, content4PointD]}
            fourthParagraph={content30FourthParagraph2}
            fifthParagraph={content30FiveParagraph}
            sixthParagraph={content30SixParagraph}
          />

          <div className="thirtieth-block">
            <p className="text-70">{title31}</p>
            <p className="text-71">{content31}</p>
          </div>
        </div>

        <GetInTouchImprintPrivacy />
      </div>
    </>
  );
};

export default ImprintPrivacy;
